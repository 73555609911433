export const CONFIRM_SHOW = "@CONFIRM/SHOW";
export const CONFIRM_HIDE = "@CONFIRM/HIDE";


export const showConfirm = (title, message, confirmAction, confirmActionParams=[]) => ({
  type: CONFIRM_SHOW,
  payload: { title, message, confirmAction, confirmActionParams },
});

export const hideConfirm = (payload) => ({
  type: CONFIRM_HIDE,
  payload
});