import GoogleLog from "./GoogleLogin";
import Azure from "./Azure";
import logoblue from 'assets/images/logo-blue.png';
import "./Login.css";
import { Avatar, Box, CssBaseline, Grid, Link, Paper, Typography } from "@mui/material";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useTheme } from "@mui/material/styles";
import { ReactComponent as Svg } from 'assets/images/bridge.svg';

export default function LoginForm() {

    const theme = useTheme();

    let heading = (
        <div className="bridge-logo">
            <img className="logo-white main-page-logo" src={logoblue} alt="logoMinnity" />
            <h1 className="text">BRIDGE</h1>
        </div>
    );
    let loginForm = (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                sm={4}
                md={7}
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    backgroundColor: theme.palette.primary.light,
                    textAlign: 'center',
                    paddingLeft: '120px',
                    paddingTop: '50px'
                }}
            >
                <Svg className="bridge-person" width={600} />
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '200px',
                        m: { xs: '0px' },
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.200', width: 60, height: 60 }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" fontSize='24px' marginBottom={3}>
                        Sign in
                    </Typography>
                    <Box sx={{ mt: 1, mb: 2 }}>
                        <GoogleLog />
                    </Box>
                    <Box>
                        <Azure />
                    </Box>
                    <Box marginTop={3} sx={{ fontSize: { xs: "20px", md: "24px" } }}>
                        <Link href="https://minnity.com/" target="_blank" rel="noreferrer"><i className="fas fa-home linking pe-3" title="MINNITY.COM"></i></Link>
                        <Link href="https://www.linkedin.com/company/minnity/" target="_blank" rel="noreferrer"><i className="fab fa-linkedin linking pe-3" title="See our LinkedIn"></i></Link>
                        <Link href="https://facebook.com/minnityapp/" target="_blank" rel="noreferrer"><i className="fab fa-facebook-square linking pe-3" title="See our Facebook"></i></Link>
                        <Link href="https://www.instagram.com/minnity_app/" target="_blank" rel="noreferrer"><i className="fab fa-instagram-square linking pe-3" title="See our Instagram"></i></Link>
                    </Box>
                </Box>
            </Grid >
        </Grid >
    )
    return (
        <div className="App">
            {heading}
            {loginForm}
        </div>
    );
}
