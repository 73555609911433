import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import moment from "moment/moment";
import If from "ui/If/If";
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";
import { fetchUserEmails } from "store/profile-history/actions";


const columns = [
  {
    field: "creationTime",
    headerName: "Creation Time",
    headerAlign: "center",
    align: "center",
    width: 90,
    flex: 0.175,
    renderCell: (params) => {
      const creationDate = params.value
        ? moment(params.value).format("YYYY-MM-DD")
        : "➖";
      const creationTime = params.value
        ? moment(params.value).format("HH:mm")
        : "";
      return (<Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        sx={{ width: '100%', height: '100%' }}
      >
        <span>{creationDate}</span>
        <span>{creationTime}</span>
      </Stack>
      )
    },
  },
  {
    field: "read",
    headerName: "isRead",
    headerAlign: "center",
    align: "center",
    width: 80,
    flex: 0.125,
    valueGetter: (params) => {
      return params.value ? "✔" : "❌";
    }
  },
  {
    field: "readTime",
    headerName: "Read Time",
    headerAlign: "center",
    align: "center",
    width: 90,
    flex: 0.175,
    renderCell: (params) => {
      const readDate = params.value
        ? moment(params.value).format("YYYY-MM-DD")
        : "➖";
      const readTime = params.value
        ? moment(params.value).format("HH:mm")
        : "";
      return (
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          sx={{ width: '100%', height: '100%' }}
        >
          <span>{readDate}</span>
          <span>{readTime}</span >
        </Stack>
      )
    },
  },
  {
    field: "type",
    headerName: "Type",
    headerAlign: "center",
    align: "center",
    width: 150,
    flex: 0.2,
  },
  {
    field: "status",
    headerName: "Status",
    headerAlign: "center",
    align: "center",
    width: 130,
    flex: 0.125,
  },
  {
    field: "awsEmailDeliveryStatus",
    headerName: "Delivery Status",
    headerAlign: "center",
    align: "center",
    width: 100,
    flex: 0.2,
  },
];


const EmailHistory = () => {

  const { id } = useParams();
  const selectedId = useSelector((state) => state?.history?.id);
  const isLoading = useSelector((state) => state?.history?.isLoading);
  const emails = useSelector((state) => state?.history?.emails);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedId !== id || emails?.length === 0) {
      dispatch(fetchUserEmails(id))
    }
  }, [id, selectedId])


  return (
    <Box sx={{ width: '100%' }}>
      <If when={isLoading}>
        Is loading...
      </If>
      <If when={!isLoading}>
        <DataGrid autoHeight
          getRowId={(row) => row.creationTime}
          rows={isLoading ? [] : emails}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          components={{
            LoadingOverlay: LoadingSkeleton,
          }}
          loading={isLoading}
        />
      </If>
    </Box>
  );
};

export default EmailHistory
