import { useDispatch, useSelector } from 'react-redux';
import Snackbar from '@mui/material/Snackbar';
import { IconButton, Slide } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import { hideAlert } from 'store/alert/actions';


function SlideTransition(props) {
    return <Slide {...props} direction="left" />;
}

const AlertDialog = () => {
    const dispatch = useDispatch();
    const isOpen = useSelector((state) => state?.alert.isOpen);
    const message = useSelector((state) => state?.alert.message);
    const alertType = useSelector((state) => state?.alert.alertType);

    const handleClose = () => {
        dispatch(hideAlert());
    };

    return (
        <Snackbar
            key="topright"
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionComponent={SlideTransition}
        >
            <MuiAlert elevation={6} variant="filled" severity={alertType} sx={{ width: "100%" }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            >
                {message}
            </MuiAlert>
        </Snackbar>
    );
}

export default AlertDialog;