import React from 'react'

const If = ({ when, children }) => {
    if (!!when) {
        return <>{children}</>
    }

    return (
        <span></span>
    )
}

export default If