import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import If from "ui/If/If";

/**
 * 
 * @param object params {url: string, action: function, formControls: {submitLabel: string, inputs: {label,name}[], description: string } }
 * 
 */
const ActionForm = ({ formControls, url, action }) => {
    const dispatch = useDispatch();
    const [formValues, setFormValues] = useState({});

    useEffect(() => {
        if (!formControls?.inputs) {
            return;
        }

        const formKeyValue = formControls.inputs.reduce((result, control) => (
            { ...result, [control.name]: "" }
        ), {});
        setFormValues(formKeyValue);
    }, []);

    const handleValueChange = (key, value) => {
        setFormValues((prev) => ({ ...prev, [key]: value }))
    }

    const handleSubmit = () => {
        dispatch(action(url, {
            ...formValues,
        }));
    }

    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: 1, marginBottom: 1 }}>
                <If when={formControls?.inputs && formControls?.inputs?.length > 0}>
                    {
                        formControls?.inputs?.map(control => (
                            <Grid key={`input-${control.name}`} item xs={12} sm={6} md={4}>
                                <TextField
                                    fullWidth
                                    label={control.label}
                                    value={formValues[control.name]}
                                    onChange={(e) => handleValueChange(control.name, e.target.value)}
                                />
                            </Grid>
                        ))
                    }
                </If>

                <Grid item xs={1}>
                    <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={handleSubmit}
                        sx={{ height: '48px' }}
                    >
                        {formControls.submitLabel}
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        {formControls.description}
                    </Typography>
                </Grid>

            </Grid>
            <Divider />
        </>
    )
}

export default ActionForm;