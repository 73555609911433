export const FETCH_TOPICS_REQUEST = "@TAXONOMY/FETCH_TOPICs_REQUEST";
export const FETCH_TOPICS_SUCCESS = "@TAXONOMY/FETCH_TOPICs_SUCCESS";
export const FETCH_TOPICS_FAILURE = "@TAXONOMY/FETCH_TOPICs_FAILURE";
export const FETCH_TAXONOMIES_REQUEST = "@TAXONOMY/FETCH_TAXONOMIES_REQUEST";
export const FETCH_TAXONOMIES_SUCCESS = "@TAXONOMY/FETCH_TAXONOMIES_SUCCESS";
export const FETCH_TAXONOMIES_FAILURE = "@TAXONOMY/FETCH_TAXONOMIES_FAILURE";
export const ASSIGN_TAXONOMY_TO_TOPIC_REQUEST =
  "@TAXONOMY/ASSIGN_TAXONOMY_TO_TOPIC_REQUEST";
export const ASSIGN_TAXONOMY_TO_TOPIC_SUCCESS =
  "@TAXONOMY/ASSIGN_TAXONOMY_TO_TOPIC_SUCCESS";
export const ASSIGN_TAXONOMY_TO_TOPIC_FAILURE =
  "@TAXONOMY/ASSIGN_TAXONOMY_TO_TOPIC_FAILURE";
