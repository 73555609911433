import * as actionTypes from "./actions.types";

export const initialState = {
  allTypes: [],
  metrics: [],
  organizations: [],
  filteredOrganizations: [],
  selectedOrganization: null,
  isLoading: false,
  clearForm: false,
  searchText: "",
};

const fetchOrganizationsRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const fetchOrganizationsSuccess = (state, action) => {
  let allTypes = action.payload.map((organization) => organization.type);
  allTypes = [...new Set(allTypes)].sort();

  return {
    ...state,
    isLoading: false,
    allTypes,
    organizations: action.payload,
    filteredOrganizations: action.payload,
    searchText: "",
  };
};

const fetchOrganizationsFailure = (state) => {
  return {
    ...state,
    allTypes: [],
    organizations: [],
    filteredOrganizations: [],
    isLoading: false,
  };
};

const deleteOrganizationRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const deleteOrganizationSuccess = (state, action) => {
  const updatedOrgList = state.organizations.filter(
    (org) => org.id !== action.payload
  );

  const updatedFilteredList = state.filteredOrganizations.filter(
    (org) => org.id !== action.payload
  );

  return {
    ...state,
    isLoading: false,
    organizations: updatedOrgList,
    filteredOrganizations: updatedFilteredList,
  };
};

const deleteOrganizationFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const filterOrganizations = (state, action) => {
  let filteredList = [...state.organizations];
  const searchText = action.payload.toLowerCase();

  if (action.payload) {
    filteredList = state.organizations.filter((org) =>
      org.name.toLowerCase().includes(searchText)
    );
  }

  return {
    ...state,
    filteredOrganizations: filteredList,
    searchText: action.payload,
  };
};

const setSelectedOrganiation = (state, action) => {
  return {
    ...state,
    selectedOrganization: action.payload,
  };
};

const fetchOrganizationRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchOrganizationSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    organizations: [action.payload],
    filterOrganizations: [action.payload],
    selectedOrganization: action.payload,
  };
};

const fetchOrganizationFailure = (state, action) => {
  return {
    ...state,
    organizations: [],
    filterOrganizations: [],
    selectedOrganization: null,
    isLoading: false,
  };
};

const fetchMetricsRequest = (state) => {
  return {
    ...state,
    metrics: [],
    isLoading: true,
  };
};

const fetchMetricsSuccess = (state, action) => {
  const metrics = action.payload.map((data) => {
    const { name, type, timeUnit, description } = data;
    const current = data?.value ? Number(data.value) : 0;
    const previous = data?.previousValue ? Number(data.previousValue) : 0;
    const diff = current - previous;
    const percent =
      previous > 0
        ? diff !== 0 || previous === current
          ? Math.round(Math.abs(diff * 100.0) / previous)
          : 100
        : previous === 0 && diff === 0
        ? 0
        : 100;
    return {
      current,
      previous,
      diff,
      percent,
      timeUnit,
      description,
      name,
      type,
    };
  });
  return {
    ...state,
    isLoading: false,
    metrics,
  };
};

const fetchMetricsFailure = (state) => {
  return {
    ...state,
    metrics: [],
    isLoading: false,
  };
};
const addUserRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const addUserSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const addUserFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};
const removeUserRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const removeUserSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const removeUserFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const addOrganizationRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const addOrganizationSuccess = (state, action) => {
  const updateOrganizations = [...state.organizations, action.payload];

  return {
    ...state,
    isLoading: false,
    clearForm: true,
    organizations: updateOrganizations,
    filterOrganizations: [],
    selectedOrganization: action.payload,
  };
};

const addOrganizationFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const updateOrganizationRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const updateOrganizationSuccess = (state, action) => {
  const updateOrganizations = state.organizations.filter(
    (org) => org.id !== action.payload.id
  );

  return {
    ...state,
    isLoading: false,
    clearForm: true,
    organizations: [...updateOrganizations, action.payload],
    filterOrganizations: [],
    selectedOrganization: action.payload,
  };
};

const updateOrganizationFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const setClearForm = (state, action) => {
  return {
    ...state,
    clearForm: action.payload,
  };
};

const reducerMap = {
  [actionTypes.FETCH_ORGANIZATIONS_REQUEST]: fetchOrganizationsRequest,
  [actionTypes.FETCH_ORGANIZATIONS_SUCCESS]: fetchOrganizationsSuccess,
  [actionTypes.FETCH_ORGANIZATIONS_FAILURE]: fetchOrganizationsFailure,
  [actionTypes.FETCH_ORGANIZATION_REQUEST]: fetchOrganizationRequest,
  [actionTypes.FETCH_ORGANIZATION_SUCCESS]: fetchOrganizationSuccess,
  [actionTypes.FETCH_ORGANIZATION_FAILURE]: fetchOrganizationFailure,
  [actionTypes.DELETE_ORGANIZATION_REQUEST]: deleteOrganizationRequest,
  [actionTypes.DELETE_ORGANIZATION_SUCCESS]: deleteOrganizationSuccess,
  [actionTypes.DELETE_ORGANIZATION_FAILURE]: deleteOrganizationFailure,
  [actionTypes.FILTER_ORGANIZATIONS]: filterOrganizations,
  [actionTypes.SET_SELECTED_ORGANIZATION]: setSelectedOrganiation,
  [actionTypes.FETCH_METRICS_REQUEST]: fetchMetricsRequest,
  [actionTypes.FETCH_METRICS_SUCCESS]: fetchMetricsSuccess,
  [actionTypes.FETCH_METRICS_FAILURE]: fetchMetricsFailure,
  [actionTypes.ADD_USER_REQUEST]: addUserRequest,
  [actionTypes.ADD_USER_SUCCESS]: addUserSuccess,
  [actionTypes.ADD_USER_FAILURE]: addUserFailure,
  [actionTypes.REMOVE_USER_REQUEST]: removeUserRequest,
  [actionTypes.REMOVE_USER_SUCCESS]: removeUserSuccess,
  [actionTypes.REMOVE_USER_FAILURE]: removeUserFailure,
  [actionTypes.ADD_ORGANIZATION_REQUEST]: addOrganizationRequest,
  [actionTypes.ADD_ORGANIZATION_SUCCESS]: addOrganizationSuccess,
  [actionTypes.ADD_ORGANIZATION_FAILURE]: addOrganizationFailure,
  [actionTypes.UPDATE_ORGANIZATION_REQUEST]: updateOrganizationRequest,
  [actionTypes.UPDATE_ORGANIZATION_SUCCESS]: updateOrganizationSuccess,
  [actionTypes.UPDATE_ORGANIZATION_FAILURE]: updateOrganizationFailure,
  [actionTypes.SET_CLEAR_FORM]: setClearForm,
};

const organizationsReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default organizationsReducer;
