import { useMemo } from "react";
import { doPostAction } from "store/operations/actions";
import MainCard from "ui/MainCard";
import ActionForm from "./ActionForm";

const Actions = () => {

    const actionsList = useMemo(() =>
    ([
        {
            url: "/api/tools/reload/config/org",
            action: doPostAction,
            formControls: {
                submitLabel: "Reload",
                inputs: null,
                description: "Reload organization's configuration"
            }
        },
        {
            url: "/api/bridge/tools/regenerateAiTopic",
            action: doPostAction,
            formControls: {
                submitLabel: "Regenerate AI topic",
                inputs: [{
                    label: "Request ID",
                    name: "name"
                }],
                description: "Regenerate AI topic"
            }
        },
        {
            url: "/api/tools/rebuildTopicStats/",
            action: doPostAction,
            formControls: {
                submitLabel: "Rebuild",
                inputs: [{
                    label: "label",
                    name: "name"
                }],
                description: "Rebuild topic stats"
            }
        }
    ]), []);


    return (
        <MainCard title="Actions">
            {
                actionsList.map((item, index) =>
                    <ActionForm key={`action-${index}`} {...item} />
                )
            }
        </MainCard>
    )
}

export default Actions;