import { useEffect, useMemo, useCallback, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Grid, Paper } from '@mui/material';
import { IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import MainCard from "ui/MainCard";
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";
import { fetchAllOrganizations } from 'store/organizations/actions';
import { assignNewOrganization, fetchAllCourses, setSelectedCourse, unassignOrganization } from 'store/courses/actions';

function CoursesManagement() {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state?.courses?.isLoading);
    const courses = useSelector((state) => state?.courses?.courses);
    const selectedCourse = useSelector((state) => state?.courses?.selectedCourse);
    const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
    const [relatedOrganizations, setRelatedOrganizations] = useState([]);

    const checkAssignStatus =
        (orgId) => {
            const isAssigned = selectedCourse.organizations.find(org => org.id === orgId)
            return !!isAssigned;
        };

    const handleClick = useCallback(
        (organization) => () => {
            if (organization.status) {
                dispatch(unassignOrganization(selectedCourse, organization));
            } else {
                dispatch(assignNewOrganization(selectedCourse, organization));
            }
            setTimeout(() => {
                dispatch(setSelectedCourse(null))
            }, 200);
        },
        [selectedCourse]
    );

    useEffect(() => {
        if (!organizations || organizations.length === 0) {
            dispatch(fetchAllOrganizations());
        }
    }, [])

    useEffect(() => {
        if (!id) {
            return navigate("/micro/courses");
        }

        if (!courses || courses?.length === 0) {
            dispatch(fetchAllCourses());
            return;
        }

        if (!selectedCourse) {
            const course = courses.find(cc => cc.topicId === id);
            dispatch(setSelectedCourse(course));
        }
    }, [id, selectedCourse, courses])

    useEffect(() => {
        if (!selectedCourse || !organizations || organizations?.length === 0) {
            return;
        }

        const newList = organizations.map(org => {
            const status = checkAssignStatus(org.id);
            return ({
                ...org,
                status
            });
        });

        setRelatedOrganizations(newList);
    }, [selectedCourse?.organizations]);

    const columns = useMemo(() =>
    ([
        {
            field: "id",
            headerName: "#ID",
            headerAlign: "center",
            align: "center",
            width: 80,
            flex: 0.15,
        },
        {
            field: "name",
            headerName: "Organization Name",
            headerAlign: "center",
            align: "center",
            width: 90,
            flex: 0.2,
        },
        {
            field: "status",
            headerName: "Status",
            headerAlign: "center",
            align: "center",
            width: 80,
            flex: 0.15,
            valueGetter: (params) => {
                return params.value ? "✔" : "❌";
            }
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: "Action",
            width: 100,
            flex: 0.25,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={
                        params.row.status ?
                            <IconCircleMinus stroke={1.75} size="1.3rem" color="#f92f60" />
                            :
                            <IconCirclePlus stroke={1.75} size="1.3rem" color="green" />
                    }
                    title={params.row.status ? "Unassign" : "Assign"}
                    label={params.row.status ? "Unassign" : "Assign"}
                    onClick={handleClick(params.row)}
                />,
            ],
        },
    ]), [handleClick]);

    return (
        <MainCard title={selectedCourse?.title}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Box sx={{ height: 670, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.id}
                                rows={isLoading ? [] : relatedOrganizations}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50, 100]}
                                components={{
                                    LoadingOverlay: LoadingSkeleton,
                                }}
                                loading={isLoading}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </MainCard>
    )
}

export default CoursesManagement;