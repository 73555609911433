import * as actionTypes from "./actions.types";

export const initialState = {
  isLoading: false,
  isChartLoading: false,
  metrics: null,
  chartData: null,
};

const fetchMetricsRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    metrics: null,
  };
};

const fetchMetricsSuccess = (state, action) => {
  const metrics = action.payload.map((data) => {
    const { name, type, timeUnit, description } = data;
    const current = data?.value ? Number(data.value) : 0;
    const previous = data?.previousValue ? Number(data.previousValue) : 0;
    const diff = current - previous;
    const percent =
      previous > 0
        ? diff !== 0 || previous === current
          ? Math.round(Math.abs(diff * 100.0) / previous)
          : 100
        : previous === 0 && diff === 0
        ? 0
        : 100;
    return {
      current,
      previous,
      diff,
      percent,
      timeUnit,
      description,
      name,
      type,
    };
  });

  return {
    ...state,
    isLoading: false,
    metrics,
  };
};

const fetchMetricsFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const fetchChartValueRequest = (state) => {
  return {
    ...state,
    isChartLoading: true,
    chartData: null,
  };
};

const fetchChartValueSuccess = (state, action) => {
  return {
    ...state,
    isChartLoading: false,
    chartData: action.payload,
  };
};

const fetchChartValueFailure = (state) => {
  return {
    ...state,
    isChartLoading: false,
  };
};

const reducerMap = {
  [actionTypes.FETCH_METRICS_REQUEST]: fetchMetricsRequest,
  [actionTypes.FETCH_METRICS_SUCCESS]: fetchMetricsSuccess,
  [actionTypes.FETCH_METRICS_FAILURE]: fetchMetricsFailure,
  [actionTypes.FETCH_CHART_VALUES_REQUEST]: fetchChartValueRequest,
  [actionTypes.FETCH_CHART_VALUES_SUCCESS]: fetchChartValueSuccess,
  [actionTypes.FETCH_CHART_VALUES_FAILURE]: fetchChartValueFailure,
};

const dashboardReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default dashboardReducer;
