import Metrics from "./components/Metrics"
import MetricsChartContainer from "./components/MetricsChartContainer"


const Dashboard = () => {
    return (
        <>
            <Metrics />
            <MetricsChartContainer />
        </>
    )
}

export default Dashboard