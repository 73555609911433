import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { IconMenu2 } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Avatar, Box, ButtonBase, Toolbar } from '@mui/material';
import Logo from '../logo/Logo';
import Profile from './profile/Profile';
import { SET_MENU } from '../../../store/sidebar/actions';

const Header = () => {
    const theme = useTheme();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const dispatch = useDispatch();
    const handleLeftDrawerToggle = () => {
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };

    return (
        <AppBar
            enableColorOnDark
            position="fixed"
            color="inherit"
            elevation={0}
            sx={{
                bgcolor: theme.palette.background.default,
                transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
                height: 57,
                width: '100vw',
                left: 0,
                paddingTop: 1
            }}
        >
            <Toolbar>
                <Box
                    sx={{
                        width: 228,
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            width: 'auto'
                        }
                    }}
                >
                    <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                        <Logo />
                    </Box>
                    <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                transition: 'all .2s ease-in-out',
                                background: theme.palette.secondary.light,
                                color: theme.palette.secondary.dark,
                                '&:hover': {
                                    background: theme.palette.secondary.dark,
                                    color: theme.palette.secondary.light
                                }
                            }}
                            onClick={handleLeftDrawerToggle}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="1.3rem" />
                        </Avatar>
                    </ButtonBase>
                </Box>
                <Box sx={{ flexGrow: 1, fontSize: 38, color: theme.palette.primary.main, paddingLeft: 1 }}>BRIDGE</Box>
                <Profile />
            </Toolbar>
        </AppBar>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
