import { useMemo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";
import { IconEdit, IconTrash, IconInfoOctagon } from '@tabler/icons-react';
import { showConfirm } from "store/confirm/actions";
import { deleteOrganization } from "store/organizations/actions";


const OrganizationsTable = () => {
    const navigate = useNavigate();
    const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
    const isLoading = useSelector((state) => state?.organizations?.isLoading);
    const dispatch = useDispatch();

    const handleDelete = useCallback(
        (id, organization) => () => {
            dispatch(showConfirm("Delete", `Are you sure you want to remove "${organization.name}" ?`, deleteOrganization, [id]));
        },
        []
    );

    const handleRedirect = useCallback(
        (id, isEdit) => () => {
            navigate(`${id}/${isEdit ? "edit" : ""}`)
        },
        []
    );

    const columns = useMemo(() =>
    ([
        {
            field: "id",
            headerName: "#ID",
            headerAlign: "center",
            align: "center",
            width: 70,
            flex: 0.15,
        },
        {
            field: "name",
            headerName: "Organization Name",
            headerAlign: "center",
            align: "center",
            width: 90,
            flex: 0.25,
        },
        {
            field: "language",
            headerName: "Language",
            headerAlign: "center",
            align: "center",
            width: 70,
            flex: 0.1,
        },
        {
            field: "type",
            headerName: "Organization Type",
            headerAlign: "center",
            align: "center",
            width: 120,
            flex: 0.3,
        },
        {
            field: 'actions',
            type: 'actions',
            width: 90,
            flex: 0.2,
            getActions: (params) => [
                <GridActionsCellItem sx={{ marginRight: '20px' }}
                    icon={<IconInfoOctagon stroke={1.75} size="1.3rem" />}
                    title="Organization Details"
                    label="Detail"
                    onClick={handleRedirect(params.id)}
                    showInMenu={window.innerWidth <= 768}
                />,
                <GridActionsCellItem sx={{ marginRight: '20px' }}
                    icon={<IconEdit stroke={1.75} size="1.3rem" />}
                    title="Edit Organization"
                    label="Edit"
                    onClick={handleRedirect(params.id, true)}
                    showInMenu={window.innerWidth <= 768}

                />,
                <GridActionsCellItem sx={{ marginRight: '20px' }}
                    icon={<IconTrash stroke={1.75} size="1.3rem" />}
                    title="Delete Organization"
                    label="Delete"
                    onClick={handleDelete(params.id, params.row)}
                    showInMenu={window.innerWidth <= 768}

                />
            ],
        },
    ]), [handleRedirect, handleDelete, window.innerWidth]);


    return (
        <Box sx={{ height: 670, width: '100%' }}>
            <DataGrid
                sx={{
                    cursor: 'pointer'
                }}
                getRowId={(row) => row.id}
                onRowClick={(row) => navigate(`${row.id}`)}
                rows={isLoading ? [] : organizations}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                components={{
                    LoadingOverlay: LoadingSkeleton,
                }}
                loading={isLoading}
            />
        </Box>
    );
};

export default OrganizationsTable;