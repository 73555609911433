import React from "react";
import { getChipData } from "util/metricsUtil";
import MetricCard from "./MetricCard";

const MetricCardsContainer = ({ metricsData }) => {
    return metricsData?.map((data, index) => {
        const chipData = getChipData(data.diff);
        const params = { ...data, index, chipData }
        return (
            <MetricCard {...params} />
        );
    });
}

export default MetricCardsContainer;