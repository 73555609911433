import MainLayout from "../layout/mainLayout/MainLayout";
import CustomerSupport from "../views/pages/customer-support/CustomerSupport";
import Profile from "../views/pages/customer-support/profile/Profile";
import Organizations from "../views/pages/organizations/Organizations";
import OrganizationDetail from "../views/pages/organizations/detail/OrganizationDetail";
import OrganizationForm from "../views/pages/organizations/form/OrganizationForm";
import Courses from "../views/pages/micro-learning/course/Courses";
import CoursesManagement from "../views/pages/micro-learning/course/management/CoursesManagement";
import TagManagement from "../views/pages/micro-learning/course/management/TagManagement";
import TaxonomyManagement from "../views/pages/micro-learning/course/management/TaxonomyManagement";
import Dashboard from "views/pages/dashboard/Dashboard";
import Actions from "views/pages/operations/Actions";

const mainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    {
      path: "customersupport",
      children: [
        {
          path: "",
          element: <CustomerSupport />,
        },
        {
          path: ":id",
          element: <Profile />,
        },
      ],
    },
    {
      path: "organizations",
      children: [
        {
          path: "",
          element: <Organizations />,
        },
        {
          path: "new",
          element: <OrganizationForm />,
        },
        {
          path: ":id",
          element: <OrganizationDetail />,
        },
        {
          path: ":id/edit",
          element: <OrganizationForm />,
        },
      ],
    },
    {
      path: "micro",
      children: [
        {
          path: "courses",
          children: [
            {
              path: "",
              element: <Courses />,
            },
            {
              path: ":id/manage",
              element: <CoursesManagement />,
            },
          ],
        },
        {
          path: "tags",
          element: <TagManagement />,
        },
        {
          path: "taxonomy",
          element: <TaxonomyManagement />,
        },
      ],
    },
    {
      path: "operations",
      children: [
        {
          path: "actions",
          element: <Actions />,
        },
      ],
    },
  ],
};

export default mainRoutes;
