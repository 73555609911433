export const authMethodOptions = [
  { id: "emailAuthentication", label: "Email" },
  { id: "phoneNumberAuthentication", label: "Phone number" },
  { id: "personnummerAuthentication", label: "Personal number" },
  { id: "oauthAuthentication", label: "OAuth" },
];

export const featureFlagOptions = [
  { id: "announcements", label: "Announcements" },
  { id: "basicSchedule", label: "Basic schedule" },
  { id: "birthdayNotification", label: "Birthday notification" },
  { id: "careHomes", label: "Care homes" },
  { id: "dailyActiveUsersStats", label: "Daily active users stats" },
  { id: "marketplace", label: "Marketplace" },
  { id: "missionSheet", label: "Mission sheet" },
  { id: "multiOrgGlobalMessages", label: "Multi org global messages" },
  { id: "personContactInfo", label: "Person contact info" },
  { id: "profileEditing", label: "Profile editing" },
  { id: "skipFeedbackPage", label: "Skip feedback page" },
  { id: "supportPlan", label: "Support plan" },
  { id: "topicCompletions", label: "Topic completions" },
  { id: "tracking", label: "Tracking" },
  { id: "learningCertificates", label: "Learning certificates" },
  { id: "generativeAiTopics", label: "Generative AI topics" },
  { id: "microLmsBranding", label: "Micro LMS UI branding" },
  { id: "brandedEmails", label: "Micro LMS branded emails" },
  { id: "hideCaregiverTeamPhoneNumbers", label: "Hide Caregiver phone numbers on person profile page" },
  { id: "microlearningAuthoringGPT4Turbo", label: "Microlearning using GPT-4-Turbo" },
  { id: "useAllAsDefaultMessageScope", label: "use ALL as default message scope." },
  { id: "logOutUsersAfterInactivityTime", label: "Automatically log out the user after N minutes of inactivity, based on the session timeout setting"},
  { id: "manuallyAssignedCourses", label: "By default, users are not assigned to any training sessions." },
  { id: "newCourseEmailNotification", label: "Send email notifications about new courses assigned to a user" },

];

export const othersOptions = [
  { id: "sendWeeklyStats", label: "Send weekly stats" },
];
