import { useState, useEffect } from 'react';
import { Outlet, redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Main } from './mainLayout.css';
import { useTheme } from '@mui/material/styles';
import { Box, CssBaseline } from '@mui/material';
import Sidebar from './sidebar/SideBar';
import Header from './header/Header';
import Auth from '../../services/Auth';
import ConfirmDialog from "../../ui/ConfirmDialog/ConfirmDialog"
import AlertDialog from '../../ui/AlertDialog/AlertDialog';
import { SET_MENU } from '../../store/sidebar/actions';

const MainLayout = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const leftDrawerOpened = useSelector((state) => state.customization.opened);
    const [isLoggedIn, setLoggedIn] = useState(Auth.isLoggedIn());
    Auth.bindLoggedInStateSetter(setLoggedIn);

    useEffect(() => {
        if (!isLoggedIn) {
            redirect("/login");
        }
    }, [isLoggedIn])


    useEffect(() => {
        handleResize();

        function handleResize() {
            if (window.innerWidth < 900) {
                dispatch({ type: SET_MENU, opened: false });
            }
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <Header />
            <Sidebar />
            <Main theme={theme} open={leftDrawerOpened}>
                <Outlet />
                <AlertDialog />
                <ConfirmDialog />
            </Main>
        </Box>
    );
};

export default MainLayout;
