import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, Typography } from '@mui/material';
import { IconMoodSad } from '@tabler/icons-react';
import { fetchUserActivities } from 'store/profile-history/actions';
import If from 'ui/If/If';

const UserActivityHistory = () => {

  const { id } = useParams();
  const selectedId = useSelector((state) => state?.history?.id);
  const activities = useSelector((state) => state?.history?.activities);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedId !== id || !activities) {
      dispatch(fetchUserActivities(id))
    }
  }, [id, selectedId])

  return (
    <Box p={2}>
      <If when={!activities?.active}>
        <Typography variant="h5">
          Sorry <IconMoodSad />
          <br /> No user activity history available.
        </Typography>
      </If>

      <If when={activities?.active}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} >
            <Typography variant="body2">
              <b>Date From:</b> {activities?.fromDate}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Typography variant="body2">
              <b>Files uploaded:</b> {activities?.filesUploaded}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Typography variant="body2">
              <b>Messages Created:</b> {activities?.messagesCreated}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} >
            <Typography variant="body2">
              <b>Profiles Visited:</b> {activities?.profilesVisited}
            </Typography>
          </Grid>
        </Grid>
      </If>
    </Box >
  )
}

export default UserActivityHistory