export const FETCH_ORGANIZATIONS_REQUEST =
  "@ORGANIZATIONS/FETCH_ORGANIZATIONS_REQUEST";
export const FETCH_ORGANIZATIONS_SUCCESS =
  "@ORGANIZATIONS/FETCH_ORGANIZATIONS_SUCCESS";
export const FETCH_ORGANIZATIONS_FAILURE =
  "@ORGANIZATIONS/FETCH_ORGANIZATIONS_FAILURE";
export const DELETE_ORGANIZATION_REQUEST =
  "@ORGANIZATIONS/DELETE_ORGANIZATION_REQUEST";
export const DELETE_ORGANIZATION_SUCCESS =
  "@ORGANIZATIONS/DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILURE =
  "@ORGANIZATIONS/DELETE_ORGANIZATION_FAILURE";
export const FILTER_ORGANIZATIONS = "@ORGANIZATIONS/FILTER_ORGANIZATIONS";
export const SET_SELECTED_ORGANIZATION =
  "@ORGANIZATIONS/SET_SELECTED_ORGANIZATION";
export const FETCH_ORGANIZATION_REQUEST =
  "@ORGANIZATIONS/FETCH_ORGANIZATION_REQUEST";
export const SET_CLEAR_FORM =
  "@ORGANIZATIONS/SET_CLEAR_FORM";
export const FETCH_ORGANIZATION_SUCCESS =
  "@ORGANIZATIONS/FETCH_ORGANIZATION_SUCCESS";
export const FETCH_ORGANIZATION_FAILURE =
  "@ORGANIZATIONS/FETCH_ORGANIZATION_FAILURE";
export const FETCH_METRICS_REQUEST = "@ORGANIZATIONS/FETCH_METRICS_REQUEST";
export const FETCH_METRICS_SUCCESS = "@ORGANIZATIONS/FETCH_METRICS_SUCCESS";
export const FETCH_METRICS_FAILURE = "@ORGANIZATIONS/FETCH_METRICS_FAILURE";
export const ADD_USER_REQUEST = "@ORGANIZATIONS/ADD_USER_REQUEST";
export const ADD_USER_SUCCESS = "@ORGANIZATIONS/ADD_USER_SUCCESS";
export const ADD_USER_FAILURE = "@ORGANIZATIONS/ADD_USER_FAILURE";
export const REMOVE_USER_REQUEST = "@ORGANIZATIONS/REMOVE_USER_REQUEST";
export const REMOVE_USER_SUCCESS = "@ORGANIZATIONS/REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAILURE = "@ORGANIZATIONS/REMOVE_USER_FAILURE";
export const ADD_ORGANIZATION_REQUEST =
  "@ORGANIZATIONS/ADD_ORGANIZATION_REQUEST";
export const ADD_ORGANIZATION_SUCCESS =
  "@ORGANIZATIONS/ADD_ORGANIZATION_SUCCESS";
export const ADD_ORGANIZATION_FAILURE =
  "@ORGANIZATIONS/ADD_ORGANIZATION_FAILURE";
export const UPDATE_ORGANIZATION_REQUEST =
  "@ORGANIZATIONS/UPDATE_ORGANIZATION_REQUEST";
export const UPDATE_ORGANIZATION_SUCCESS =
  "@ORGANIZATIONS/UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILURE =
  "@ORGANIZATIONS/UPDATE_ORGANIZATION_FAILURE";
