import * as actionTypes from "./actions";


const savedProfile = JSON.parse(localStorage.getItem("user"));

export const initialState = {
  name: savedProfile?.name,
  email: savedProfile?.email,
  avatar: savedProfile?.avatar,
};

const setUserInfo = (state, action) => {
  localStorage.setItem("user", JSON.stringify(action.payload));
  return {
    ...state,
    ...action.payload,
  };
};

const clearUserInfo = () => {
  localStorage.removeItem("user");
  return {
    ...initialState,
  };
};

const reducerMap = {
  [actionTypes.SET_USER_INFO]: setUserInfo,
  [actionTypes.CLEAR_USER_INFO]: clearUserInfo,
};

const loginReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default loginReducer;
