import { Chip, Grid, Paper, Stack, Tooltip, Typography, Zoom } from '@mui/material'
import React from 'react'
import If from 'ui/If/If'
import { getTimeUnitValue } from 'util/timeUnit'

const MetricCard = ({
    timeUnit, current, previous, description, index, percent, chipData
}) => {
    return (
        <Grid
            key={`mertics-${index}`}
            item xs={12} sm={4} md={3}>
            <Tooltip
                key={index}
                placement="top"
                title={description}
                TransitionComponent={Zoom}
            >
                <Paper sx={{ marginBottom: '7px !important', padding: '1rem', boxShadow: 2, minWidth: "180px", minHeight: '157px' }} >
                    <Typography noWrap variant="caption"
                        overflow="hidden"
                        maxWidth="100%"
                        display="inline-block"
                    >
                        {description}
                    </Typography>
                    <hr />
                    <Stack direction="row" justifyContent="space-between" alignContent="center" rowGap={2} mt={1}>
                        <div>
                            <Typography variant="body2" component="span" mr={1} >
                                {getTimeUnitValue(timeUnit, true)}:
                            </Typography>
                            <Typography variant="h5" component="span">
                                {current}
                            </Typography>
                        </div>
                        <If when={timeUnit !== "epoch"}>
                            <Chip
                                label={percent + " %"}
                                color={chipData.color}
                                icon={chipData.icon}
                                sx={{ borderRadius: "4px", marginLeft: "15px" }}
                                size="small"
                                variant="outlined"
                            />
                        </If>
                    </Stack>
                    <If when={timeUnit !== "epoch"}>
                        <div>
                            <Typography variant="body2" component="span" mr={1} color="GrayText">
                                {getTimeUnitValue(timeUnit, false)}:
                            </Typography>
                            <Typography variant="h5" component="span" color="GrayText">
                                {Math.round(previous)}
                            </Typography>
                        </div>
                    </If>
                </Paper>
            </Tooltip>
        </Grid>
    )
}

export default MetricCard