import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { Grid } from '@mui/material';
import { fetchOrganizationDetail, fetchOrganizationMetrics, setSelectedOrganization } from 'store/organizations/actions';
import OrganizationCard from 'views/pages/organizations/components/OrganizationCard';
import MetricCardsContainer from 'ui/MetricCard/MetricCardsContainer';

const OrganizationDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
  const metricsData = useSelector((state) => state?.organizations?.metrics);

  useEffect(() => {
    if (!id) {
      return navigate("/organizations");
    }

    if (organizations?.length === 0) {
      dispatch(fetchOrganizationDetail(id))
    } else {
      const selected = organizations.find(organization => organization.id === +id);
      dispatch(setSelectedOrganization(selected));
    }

    dispatch(fetchOrganizationMetrics(id));
  }, [id])

  return (
    <>
      <OrganizationCard />
      <Grid container spacing={2}
        sx={{ marginTop: 1 }}>
        <MetricCardsContainer metricsData={metricsData} />
      </Grid>

    </>
  )
}
export default OrganizationDetail;