import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { setUserInfo } from "store/login/actions";
import Auth from "services/Auth";
import Api from "api/Api"
import "./Login.css";

export default function GoogleLog() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clientId =
    "160696423885-pb97t0vb8a61dautgl5a8cgc4bcfs2ql.apps.googleusercontent.com";
  const Authorize = async (response) => {
    await Api({
      method: "post",
      url: "/auth/bridge/google",
      data: {
        idToken: response.tokenId,
      },
      header: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        let minnityToken = response.data.jwtToken;
        Auth.postOAuthLogin(minnityToken);
        navigate("/");
      })
      .catch(function (error) {
      });
  };

  const onSuccess = (response) => {
    Authorize(response);
    const name = response.profileObj.name;
    const email = response.profileObj.email;
    const avatar = response.profileObj.imageUrl;
    dispatch(setUserInfo({ name, avatar, email }));
  };

  return (
    <div>
      <GoogleLogin
        className="google"
        clientId={clientId}
        buttonText="Login with Google"
        onSuccess={onSuccess}
        cookiePolicy={"single_host_origin"}
        uxMode="popup"
        accessType="online"
        autoLoad={false}
        isSignedIn={false}
      />
    </div>
  );
}
