import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Box, MenuItem, Select } from "@mui/material";
import { showConfirm } from "store/confirm/actions";
import { assignTopicToTaxonomy } from "store/taxonomy/actions";


const TaxonomiesTable = () => {
    const dispatch = useDispatch();
    const topics = useSelector((state) => state?.taxonomy?.filteredTopics);
    const taxonomies = useSelector((state) => state?.taxonomy?.taxonomies);
    const isLoading = useSelector((state) => state?.taxonomy?.isLoading);

    const handleEdit = (row, taxonomyId) => {
        if (!taxonomyId) {
            return;
        }

        const selectedTaxonomy = taxonomies.find(taxo => taxo.id === taxonomyId);
        dispatch(showConfirm("Confirm",
            `Are you sure you want to update ${row.title}'s taxonomy to ${selectedTaxonomy.name}?`,
            assignTopicToTaxonomy,
            [row.topicId, row.title, selectedTaxonomy.id, selectedTaxonomy.name]))
    };

    const columns = useMemo(() =>
    ([
        {
            field: "title",
            headerName: "Topic Name",
            headerAlign: "center",
            align: "center",
            width: 90,
            flex: 0.3,
        },
        {
            field: "providerName",
            headerName: "Provider",
            headerAlign: "center",
            align: "center",
            width: 80,
            flex: 0.3,
        },
        {
            field: "taxonomyName",
            headerName: "Taxonomy",
            headerAlign: "center",
            align: "center",
            width: 120,
            flex: 0.4,
            renderCell: (params) => (
                <Select
                    style={{ width: 300 }}
                    size="small"
                    fullWidth
                    defaultValue={params.row?.taxonomyId ?? "0"}
                    value={params.row?.taxonomyId ?? "0"}
                    onChange={(e) => handleEdit(params.row, e.target.value)}
                >
                    <MenuItem value={null}>
                        Choose taxonomy
                    </MenuItem>
                    {taxonomies.map((tax) => (
                        <MenuItem
                            key={`${params.id}-${tax.id}`}
                            value={tax.id}
                        >
                            {tax.name}
                        </MenuItem>
                    ))}
                </Select>
            )
        },
    ]), [taxonomies]);

    return (
        <Box sx={{ height: 670, width: '100%' }}>
            <DataGrid
                getRowId={(row) => row.topicId}
                rows={isLoading ? [] : topics}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                loading={isLoading || taxonomies?.length === 0 || topics?.length === 0}
            />
        </Box>
    );
};

export default TaxonomiesTable;