export const msalConfig = {
  auth: {
    clientId: "a1d9c29e-4c51-443e-b9d1-b2011b50865a",
    authority: "https://login.microsoftonline.com/common",
    redirectURI: "http://localhost:3000/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
