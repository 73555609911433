import { useDispatch } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import { searchProfiles } from 'store/profiles/actions';
import ProfilesTable from './components/ProfilesTable';
import SearchBox from 'ui/SearchBox/SearchBox';

function CustomerSupport() {
    const dispatch = useDispatch();

    const onSearchChange = (searchText) => {
        dispatch(searchProfiles(searchText));
    };

    return (
        <>
            <Grid container spacing={{ xs: 1, md: 1, lg: 1 }}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <SearchBox placeholder={"Search Customer"} handleChange={onSearchChange} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <ProfilesTable />
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default CustomerSupport;