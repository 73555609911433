import * as actionTypes from "./actions";

export const initialState = {
  isOpen: false,
  isConfirmed: false,
  title: "Confirm",
  message: null,
  confirmAction: null,
  confirmActionParams: [],
};

const showConfirm = (state, action) => {
  return {
    ...state,
    isOpen: true,
    isConfirmed: false,
    title: action.payload.title ?? "Confirm",
    message: action.payload.message,
    confirmAction: action.payload.confirmAction,
    confirmActionParams: action.payload.confirmActionParams,
  };
};

const hideConfirm = (state, action) => {
  return {
    ...initialState,
    isConfirmed: action.payload,
  };
};

const reducerMap = {
  [actionTypes.CONFIRM_SHOW]: showConfirm,
  [actionTypes.CONFIRM_HIDE]: hideConfirm,
};

const cofirmReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default cofirmReducer;
