import { Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMetrics } from 'store/dashboard/actions';
import If from 'ui/If/If';
import LoadingSkeleton from 'ui/LoadingSkeleton/LoadingSkeleton';
import MetricCardsContainer from 'ui/MetricCard/MetricCardsContainer';


const Metrics = () => {
    const dispatch = useDispatch();
    const metricsData = useSelector((state) => state?.dashboard?.metrics);
    const isLoading = useSelector((state) => state?.dashboard?.isLoading);
    useEffect(() => {
        dispatch(fetchMetrics());
    }, [])

    return (
        <Grid container spacing={2}
        >
            <If when={isLoading}>
                <LoadingSkeleton />
            </If>

            <If when={!isLoading && !!metricsData}>
                <Grid
                    item xs={12}>
                    <Typography noWrap variant="h2" color="#85868a"
                    >
                        Metrics
                    </Typography>
                </Grid>
                <MetricCardsContainer metricsData={metricsData} />
            </If>
        </Grid>
    )
}

export default Metrics