import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Stack } from "@mui/material";
import moment from "moment/moment";
import If from "ui/If/If";
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";
import { fetchUserLogins } from "store/profile-history/actions";


const columns = [
  {
    field: "dateTime",
    headerName: "Login Time",
    headerAlign: "center",
    align: "center",
    width: 90,
    flex: 0.175,
    renderCell: (params) => {
      const loginDate = params.value
        ? moment(params.value).format("YYYY-MM-DD")
        : "➖";
      const loginTime = params.value
        ? moment(params.value).format("HH:mm")
        : "";
      return (<Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={0.5}
        sx={{ width: '100%', height: '100%' }}
      >
        <span>{loginDate}</span>
        <span>{loginTime}</span>
      </Stack>
      )
    },
  },
  {
    field: "country",
    headerName: "Country",
    headerAlign: "center",
    align: "center",
    width: 80,
    flex: 0.125,
  },
  {
    field: "city",
    headerName: "City",
    headerAlign: "center",
    align: "center",
    width: 90,
    flex: 0.175,
  },
  {
    field: "success",
    headerName: "Success",
    headerAlign: "center",
    align: "center",
    width: 150,
    flex: 0.2,
    valueGetter: (params) => {
      return params.value ? "✔" : "❌";
    }
  },
];

const LoginHistory = () => {

  const { id } = useParams();
  const selectedId = useSelector((state) => state?.history?.id);
  const isLoading = useSelector((state) => state?.history?.isLoading);
  const logins = useSelector((state) => state?.history?.logins);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedId !== id || logins?.length === 0) {
      dispatch(fetchUserLogins(id))
    }
  }, [id, selectedId])

  return (
    <Box sx={{ width: '100%' }}>
      <If when={isLoading}>
        Is loading...
      </If>
      <If when={!isLoading}>
        <DataGrid autoHeight
          getRowId={(row) => row.dateTime}
          rows={isLoading ? [] : logins}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          components={{
            LoadingOverlay: LoadingSkeleton,
          }}
          loading={isLoading}
        />
      </If>
    </Box>
  )
}

export default LoginHistory