import * as actionTypes from "./actions.types";

export const initialState = {
  isLoading: false,
  clearForm: false,
};

const doActionRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const doActionSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm:true,
  };
};

const doActionFailure = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm: false,
  };
};

const reducerMap = {
  [actionTypes.DO_ACTION_REQUEST]: doActionRequest,
  [actionTypes.DO_ACTION_SUCCESS]: doActionSuccess,
  [actionTypes.DO_ACTION_FAILURE]: doActionFailure,
};

const operationsReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default operationsReducer;
