import { Box, Skeleton } from "@mui/material";

const LoadingSkeleton = ({rowCount}) => (
    <Box
        sx={{
            height: 'max-content',
        }}
    >
        {[...Array(rowCount?? 3)].map((_, index) => (
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={index} />
        ))}
    </Box>
);

export default LoadingSkeleton