import * as Msal from "msal";
import { msalConfig } from "./authConfig";
import Auth from "services/Auth";

const loginRequest = {
  scopes: ["User.Read"],
};

const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};

const myMSALObj = new Msal.UserAgentApplication(msalConfig);

const callMSGraph = (endpoint, token, callback) => {
  const headers = new Headers();
  const bearer = `Bearer ${token}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  fetch(endpoint, options)
    .then((response) => response.json())
    .then((response) => callback(response, endpoint))
    .catch((error) => alert(JSON.stringify(error)));
};

const updateUI = (data, endpoint) => {
  alert(endpoint);
};

export const signIn = () => {
  myMSALObj
    .loginPopup(loginRequest)
    .then((loginResponse) => {
      Auth.postAzureLogin(JSON.parse(JSON.stringify(loginResponse)));
    })
    .catch((error) => {
      alert(error);
      alert(JSON.stringify(error));
    });
};

export const signOut = () => {
  myMSALObj.logout();
};

function getTokenPopup(request) {
  return myMSALObj.acquireTokenSilent(request).catch((error) => {
    alert(JSON.stringify(error));
    alert("silent token acquisition fails. acquiring token using popup");

    // fallback to interaction when silent call fails
    return myMSALObj
      .acquireTokenPopup(request)
      .then((tokenResponse) => {
        return tokenResponse;
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  });
}

export const seeProfile = () => {
  if (myMSALObj.getAccount()) {
    getTokenPopup(loginRequest)
      .then((response) => {
        callMSGraph(
          graphConfig.graphMeEndpoint,
          response.accessToken,
          updateUI
        );
      })
      .catch((error) => {
        alert(JSON.stringify(error));
      });
  } else {
    alert("not loggedin, please login first");
  }
};
