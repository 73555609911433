export const ALERT_SHOW = "@ALERT/SHOW";
export const ALERT_HIDE = "@ALERT/HIDE";

export const showSuccessAlert = (message) => showAlert("success", message);
export const showErrorAlert = (message) => showAlert("error", message);

export const showAlert = (alertType, message) => ({
  type: ALERT_SHOW,
  payload: { alertType, message },
});

export const hideAlert = () => ({
  type: ALERT_HIDE,
});
