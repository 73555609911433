import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { hideConfirm } from 'store/confirm/actions';


const ConfirmDialog = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const confirmState = useSelector((state) => state?.confirm);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleConfirm = () => {
        dispatch(confirmState.confirmAction(...confirmState.confirmActionParams));
        dispatch(hideConfirm(true));
    };

    const handleClose = () => {
        dispatch(hideConfirm(false));
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={confirmState.isOpen}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle id="responsive-dialog-title">
                {confirmState.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {confirmState.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    No
                </Button>
                <Button onClick={handleConfirm} autoFocus>
                    Yes
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmDialog