import { useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Grid, Paper } from '@mui/material';
import { IconEdit } from '@tabler/icons-react';
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";
import { fetchAllCourses, setSelectedCourse } from "store/courses/actions";
import MainCard from 'ui/MainCard';

function Courses() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state?.courses?.isLoading);
    const courses = useSelector((state) => state?.courses?.courses);

    useEffect(() => {
        dispatch(fetchAllCourses());
    }, [])

    const handleRedirect = useCallback(
        (course) => () => {
            dispatch(setSelectedCourse(course));
            navigate(`${course.topicId}/manage`)
        },
        []
    );

    const columns = useMemo(() =>
    ([
        {
            field: "topicId",
            headerName: "#ID",
            headerAlign: "center",
            align: "center",
            width: 80,
            flex: 0.15,
        },
        {
            field: "title",
            headerName: "Course name",
            headerAlign: "center",
            align: "center",
            width: 90,
            flex: 0.2,
        },
        {
            field: "providerName",
            headerName: "Provider",
            headerAlign: "center",
            align: "center",
            width: 80,
            flex: 0.15,
        },
        {
            field: 'actions',
            type: 'actions',
            headerName: "Manage",
            width: 100,
            flex: 0.25,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<IconEdit stroke={1.75} size="1.3rem" />}
                    title="Manage course"
                    label="Edit"
                    onClick={handleRedirect(params.row)}
                />,
            ],
        },
    ]), [handleRedirect]);

    return (
        <MainCard title="Courses">
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Box sx={{ height: 670, width: '100%' }}>
                            <DataGrid
                                getRowId={(row) => row.topicId}
                                rows={isLoading ? [] : courses}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 25,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10, 25, 50, 100]}
                                components={{
                                    LoadingOverlay: LoadingSkeleton,
                                }}
                                loading={isLoading}
                            />
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </MainCard>
    )
}

export default Courses;