import React from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import themes from "./themes";
import NavigationScroll from "./layout/NavigationScroll";
import AppRoutes from "./routes/AppRoutes";
import "assets/scss/style.scss";


export default function App() {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <NavigationScroll>
            <AppRoutes />
        </NavigationScroll>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
