import moment from "moment/moment";

const year = moment().format("yyyy");
const currentMonth = moment().format("MMMM");
const previousMonth = moment().add(-1, "month").format("MMMM");
const today = moment().format("dddd");
const yesterday = moment().add(-1, "day").format("dddd");
const currentHour = moment().format("HH");
const previousHour = moment().add(-1, "hour").format("HH");
const week = moment().week();

export const getTimeUnitValue = (timeUnit, isCurrent) => {
  if (timeUnit === "year") {
    return isCurrent ? year : year - 1;
  } else if (timeUnit === "month") {
    return isCurrent ? currentMonth : previousMonth;
  } else if (timeUnit === "week") {
    return `Week ${isCurrent ? week : week - 1}`;
  } else if (timeUnit === "day") {
    return isCurrent ? today : yesterday;
  } else if (timeUnit === "hour") {
    return `at ${isCurrent ? currentHour : previousHour}`;
  }
  return timeUnit;
};
