import * as actionTypes from "./actions";

export const initialState = {
  isOpen: false,
  alertType: "success",
  message: null,
};

const showAlert = (state, action) => {
  return {
    ...state,
    isOpen: true,
    alertType: action.payload.alertType,
    message: action.payload.message,
  };
};

const hideAlert = () => {
  return {
    ...initialState,
  };
};

const reducerMap = {
  [actionTypes.ALERT_SHOW]: showAlert,
  [actionTypes.ALERT_HIDE]: hideAlert,
};

const alertReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default alertReducer;
