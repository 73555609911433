import React from "react";
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

const SearchBox = ({ placeholder, handleChange }) => {
  return (
    <Box className="search"
      type="search"
    >
      <TextField fullWidth onChange={(e) => handleChange(e.target.value)} label={placeholder} />
    </Box>
  );
}

export default SearchBox;