import AuthApi from "../api/AuthApi";
import Api from "../api/Api";

const tokenKey = "_token";
const userKey = "_user";

class Auth {
  setLoggedIn = () => {};

  isLoggedIn() {
    return this._getToken() != null;
  }

  postOAuthLogin(minnityJwtToken) {
    this._setToken(minnityJwtToken);
    this.setLoggedIn(true);
  }

  postAzureLogin(azureData) {
    window.location = "/";
    Api({
      method: "post",
      url: "/auth/bridge/azure",
      data: {
        azureData: azureData,
      },
      header: {
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        let minnityToken = response.data.jwtToken;
        Auth.postOAuthLogin(minnityToken);
      })
      .catch(function (error) {
      });
  }

  async login(data) {
    await AuthApi.authenticate(data)
      .then((response) => {
        this._setToken(response.data.token);
        this._setUser(response.data.user);
        this.setLoggedIn(true);
        return true;
      })
      .catch((error) => {
        this.setLoggedIn(false);
        this._clearToken();
        this._clearUser();
        window.location = "/login";
        return false;
      });
  }

  logout() {
    this.setLoggedIn(false);
    this._clearToken();
    this._clearUser();
    window.location = "/login";
  }

  bindLoggedInStateSetter(loggedInStateSetter) {
    this.setLoggedIn = loggedInStateSetter;
  }

  getAuthorizationHeader() {
    return "Bearer " + this._getToken();
  }

  _getToken() {
    return window.sessionStorage.getItem(tokenKey);
  }

  _setToken(token) {
    window.sessionStorage.setItem(tokenKey, token);
  }

  _clearToken() {
    window.sessionStorage.removeItem(tokenKey);
  }

  getUser() {
    return JSON.parse(window.sessionStorage.getItem(userKey));
  }

  _setUser(user) {
    const userJson = JSON.stringify(user);
    window.sessionStorage.setItem(userKey, userJson);
  }

  _clearUser() {
    window.sessionStorage.removeItem(userKey);
  }
}

export default new Auth();
