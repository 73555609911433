import React from 'react'
import MetricsFilter from './MetricsFilter';
import { Grid, Paper } from '@mui/material';
import MetricsAreaChart from './MetricsAreaChart';

const MetricsChartContainer = () => {
    return (
        <Paper sx={{ padding: 2, marginTop: "1rem" }}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <MetricsFilter />
                </Grid>
                <Grid item xs={12}>
                    <MetricsAreaChart />
                </Grid>
            </Grid>
        </Paper>
    )
}

export default MetricsChartContainer;