import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

const doActionRequest = () => ({
  type: actionTypes.DO_ACTION_REQUEST,
});

const doActionSuccess = (payload) => ({
  type: actionTypes.DO_ACTION_SUCCESS,
  payload,
});

const doActionFailure = (payload) => ({
  type: actionTypes.DO_ACTION_FAILURE,
  payload,
});

export const doPostAction = (url, payload = {}) => {
  const actions = {
    request: doActionRequest,
    success: doActionSuccess,
    failure: doActionFailure,
  };
  const messages = {
    success: `action ran successfully.`,
    failure: `Sorry, sometings went wrong.`,
  };
  return httpClient.run(Api.post, url, payload, actions, messages);
};
