import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

const fetchTagsRequest = () => ({
  type: actionTypes.FETCH_TAGS_REQUEST,
});

const fetchTagsSuccess = (payload) => ({
  type: actionTypes.FETCH_TAGS_SUCCESS,
  payload,
});

const fetchTagsFailure = (payload) => ({
  type: actionTypes.FETCH_TAGS_FAILURE,
  payload,
});

export const fetchAllTags = () => {
  const url = "/api/bridge/microlearning/tags";
  const actions = {
    request: fetchTagsRequest,
    success: fetchTagsSuccess,
    failure: fetchTagsFailure,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Tags. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

export const setSelectedTag = (payload) => ({
  type: actionTypes.SET_SELECTED_TAG,
  payload,
});

export const setFilter = (payload) => ({
  type: actionTypes.SET_FLITER_TAG,
  payload,
});

//=====ADD TAG======
const addTagRequest = () => ({
  type: actionTypes.ADD_TAG_REQUEST,
});

const addTagSuccess = (payload) => ({
  type: actionTypes.ADD_TAG_SUCCESS,
  payload,
});

const addTagFailure = (payload) => ({
  type: actionTypes.ADD_TAG_FAILURE,
  payload,
});

export const addTag = (tag) => {
  const url = `/api/bridge/microlearning/tags`;

  const actions = {
    request: addTagRequest,
    success: addTagSuccess,
    failure: addTagFailure,
  };

  const messages = {
    success: `${tag.name} addd successfully.`,
    failure: `Sorry, Unable to add ${tag.name}. Please try again later.`,
  };
  return httpClient.run(Api.post, url, tag, actions, messages);
};

//=====EDIT TAG======
const editTagRequest = () => ({
  type: actionTypes.UPDATE_TAG_REQUEST,
});

const editTagSuccess = (payload) => ({
  type: actionTypes.UPDATE_TAG_SUCCESS,
  payload,
});

const editTagFailure = (payload) => ({
  type: actionTypes.UPDATE_TAG_FAILURE,
  payload,
});

export const updateTag = (tag) => {
  const url = `/api/bridge/microlearning/tags`;

  const actions = {
    request: editTagRequest,
    success: editTagSuccess,
    failure: editTagFailure,
  };
  const messages = {
    success: `${tag.name} updated successfully.`,
    failure: `Sorry, Unable to update ${tag.name}. Please try again later.`,
  };
  return httpClient.run(Api.put, url, tag, actions, messages);
};

//=====DELETE TAG======
const deleteTagRequest = () => ({
  type: actionTypes.DELETE_TAG_REQUEST,
});

const deleteTagSuccess = (_result, payload) => ({
  type: actionTypes.DELETE_TAG_SUCCESS,
  payload,
});

const deleteTagFailure = (payload) => ({
  type: actionTypes.DELETE_TAG_FAILURE,
  payload,
});

export const deleteTag = (id, tagName) => {
  const url = `/api/bridge/microlearning/tags/${id}`;

  const actions = {
    request: deleteTagRequest,
    success: deleteTagSuccess,
    failure: deleteTagFailure,
    successParams: id,
  };
  const messages = {
    success: "Tag removed successfully.",
    failure: `Sorry, Unable to Remove ${tagName}. Please try again later.`,
  };
  return httpClient.run(Api.delete, url, {}, actions, messages);
};

export const showTagModal = (payload = true) => ({
  type: actionTypes.SHOW_TAG_MODAL,
  payload,
});

export const setClearForm = (payload = false) => ({
  type: actionTypes.SET_CLEAR_FORM,
  payload,
});
