import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Grid, Typography, Switch, TextField, InputLabel, Select, MenuItem, FormControl, OutlinedInput, FormControlLabel, Button, FormHelperText } from "@mui/material";
import { addNewOrganization, fetchAllOrganizations, fetchOrganizationDetail, setClearForm, setSelectedOrganization, updateOrganization } from "store/organizations/actions";
import { languages } from "util/languages";
import { authMethodOptions, featureFlagOptions, othersOptions } from "util/organizatoinOptions";
import MainCard from "ui/MainCard";
import If from "ui/If/If";


const OrganizationForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const canClearForm = useSelector((state) => state?.organizations?.clearForm);
    const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
    const selectedOrganization = useSelector((state) => state?.organizations?.selectedOrganization);
    const allTypes = useSelector((state) => state?.organizations?.allTypes);
    const isWorking = useSelector((state) => state?.organizations?.isLoading);
    const [featureFlags, setFeatureFlags] = useState({});
    const [authMethods, setAuthMethods] = useState({});
    const [others, setOthers] = useState({});
    const [formValues, setFormValues] = useState({
        name: "",
        language: "sv",
        type: "",
    });
    const [formValidations, setFormValidations] = useState({
        nameIsValid: true,
        typeIsValid: true,
    });

    useEffect(() => {
        createStateObjects();
    }, []);

    useEffect(() => {
        if (allTypes && allTypes.length > 0) {
            handleValueChange("type", allTypes[0])
        } else {
            dispatch(fetchAllOrganizations());
        }
    }, [allTypes]);

    useEffect(() => {
        if (!id) {
            // the Add new mode is enabled so we don't need to set form default values by selected organization.
            dispatch(setSelectedOrganization(null));
            return;
        }

        if (organizations?.length === 0) {
            dispatch(fetchOrganizationDetail(id))
        } else {
            const selected = organizations.find(organization => organization.id === +id);
            dispatch(setSelectedOrganization(selected));
        }
    }, [id, organizations]);

    useEffect(() => {
        if (!selectedOrganization) {
            return;
        }

        setFormValues({
            name: selectedOrganization.name,
            language: selectedOrganization.language,
            type: selectedOrganization.type,
        });
        setFeatureFlags((prev) => ({ ...prev, ...selectedOrganization.featureFlagMap }));

        const authMethodDefaultState = authMethodOptions.reduce((result, item) => (
            { ...result, [item.id]: selectedOrganization[item.id] }
        ), {});
        setAuthMethods((prev) => ({ ...prev, ...authMethodDefaultState }));

        const othersDefaultState = othersOptions.reduce((result, item) => (
            { ...result, [item.id]: selectedOrganization[item.id] }
        ), {});
        setOthers((prev) => ({ ...prev, ...othersDefaultState }));
    }, [selectedOrganization]);

    useEffect(() => {
        if (!canClearForm) {
            return;
        }

        if (id) {
            // Edit Mode
            dispatch(setClearForm());
            navigate("/organizations");
            return;
        }

        // ADD NEW Mode
        createStateObjects();

        setFormValues({
            name: "",
            language: "sv",
            type: allTypes[0] ?? "",
        });

        setFormValidations({
            nameIsValid: true,
            typeIsValid: true,
        });
    }, [canClearForm])

    const createStateObjects = () => {
        const flagsDefaultState = featureFlagOptions.reduce((result, item) => (
            { ...result, [item.id]: false }
        ), {});
        setFeatureFlags((prev) => ({ ...prev, ...flagsDefaultState }));

        const authMethodDefaultState = authMethodOptions.reduce((result, item) => (
            { ...result, [item.id]: false }
        ), {});
        setAuthMethods((prev) => ({ ...prev, ...authMethodDefaultState }));

        const othersDefaultState = othersOptions.reduce((result, item) => (
            { ...result, [item.id]: false }
        ), {});
        setOthers((prev) => ({ ...prev, ...othersDefaultState }));
    }

    const checkFormValidation = () => {
        let isValid = true;

        if (!formValues.name) {
            setFormValidations(prev => ({ ...prev, nameIsValid: false }));
            isValid = false;
        }

        if (!formValues.type) {
            setFormValidations(prev => ({ ...prev, typeIsValid: false }));
            isValid = false;
        }

        return isValid;
    }

    const handleValueChange = (key, value) => {
        setFormValues((prev) => ({ ...prev, [key]: value }))
        setFormValidations(prev => ({ ...prev, [`${key}IsValid`]: !!value }))
    }

    const handleSwitches = (category, key, value) => {
        if (category === "authMethod") {
            setAuthMethods(prev => ({ ...prev, [key]: value }));
        }
        else if (category === "flag") {
            setFeatureFlags(prev => ({ ...prev, [key]: value }));
        }
        else if (category === "other") {
            setOthers(prev => ({ ...prev, [key]: value }));
        }
    }

    const handleBack = () => {
        dispatch(setSelectedOrganization(null));
        navigate("/organizations");
    }

    const handleSave = () => {
        if (!checkFormValidation()) {
            return;
        }

        if (id) {
            // Edit Mode
            dispatch(updateOrganization({
                id,
                ...formValues,
                ...authMethods,
                ...others,
                featureFlagMap: featureFlags,
            }));
        } else {
            // Add New Mode 
            dispatch(addNewOrganization({
                ...formValues,
                ...authMethods,
                ...others,
                featureFlagMap: featureFlags,
            }));
        }
    }

    return (
        <MainCard title={(id ? "Edit" : "Add") + " organization"}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <TextField
                        required
                        fullWidth
                        label="Name"
                        error={!formValidations.nameIsValid}
                        helperText={formValidations.nameIsValid ? "" : "please fill this field."}
                        value={formValues.name}
                        onChange={(e) => handleValueChange("name", e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="lbl-lang">Language</InputLabel>
                        <Select
                            labelId="lbl-lang"
                            defaultValue={formValues.language}
                            value={formValues.language}
                            onChange={(e) => handleValueChange("language", e.target.value)}
                            input={<OutlinedInput label="language" />}
                        >
                            {languages.map((lang) => (
                                <MenuItem
                                    key={lang.id}
                                    value={lang.id}
                                >
                                    {lang.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth>
                        <InputLabel id="lbl-type">Type</InputLabel>
                        <Select
                            labelId="lbl-type"
                            defaultValue={!!allTypes && allTypes?.length > 0 ? allTypes[0] : ""}
                            value={formValues.type}
                            onChange={(e) => handleValueChange("type", e.target.value)}
                            error={!formValidations.typeIsValid}
                            input={<OutlinedInput label="type" />}
                        >
                            {allTypes.map((type) => (
                                <MenuItem
                                    key={type}
                                    value={type}
                                >
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                        <If when={!formValidations.typeIsValid}>
                            <FormHelperText>"please select a type."</FormHelperText>
                        </If>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={2} mt={2}>
                <Grid item xs={12}>
                    <Typography variant="h5" component="div">
                        Authentication methods
                    </Typography>
                </Grid>
                {authMethodOptions.map((method) => (
                    <Grid item xs={12} sm={4} md={3} key={"method" + method.id}>
                        <FormControlLabel
                            control={<Switch
                                size="small"
                                defaultChecked={authMethods[method.id] ?? false}
                                checked={authMethods[method.id] ?? false}
                                onChange={(e) => handleSwitches("authMethod", method.id, e.target.checked)}
                            />}
                            label={method.label}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                        Feature flags
                    </Typography>
                </Grid>
                {featureFlagOptions.map((flag) => (
                    <Grid item xs={12} sm={4} md={3} key={"flag" + flag.id}>
                        <FormControlLabel
                            control={<Switch
                                size="small"
                                defaultChecked={featureFlags[flag.id] ?? false}
                                checked={featureFlags[flag.id] ?? false}
                                onChange={(e) => handleSwitches("flag", flag.id, e.target.checked)}
                            />}
                            label={flag.label}
                        />
                    </Grid>
                ))}
            </Grid>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography sx={{ mt: 4, mb: 2 }} variant="h5" component="div">
                        Other
                    </Typography>
                </Grid>
                {othersOptions.map((item) => (
                    <Grid item xs={12} sm={4} md={3} key={"other" + item.id}>
                        <FormControlLabel
                            control={<Switch
                                size="small"
                                defaultChecked={others[item.id] ?? false}
                                checked={others[item.id] ?? false}
                                onChange={(e) => handleSwitches("other", item.id, e.target.checked)}
                            />}
                            label={item.label}
                        />
                    </Grid>
                ))}
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={2} md={1} mt={3} mx={1}>
                    <Button
                        variant="contained"
                        size="medium"
                        fullWidth
                        onClick={handleSave}
                        disabled={isWorking}
                    >
                        Save
                    </Button>
                </Grid>
                <Grid item xs={12} sm={2} md={1} mt={3} mx={2}>
                    <Button
                        variant="outlined"
                        color="error"
                        size="medium"
                        fullWidth
                        onClick={handleBack}
                    >
                        Cancel
                    </Button>
                </Grid>
            </Grid>
        </MainCard>
    )
}

export default OrganizationForm;