import Api from "./Api";

class AuthApi {
    async authenticate(data) {
        const credentials = '{"username":"'
                                + data.username +
                                '", "password":"'
                                + data.password + 
                                '"}'
        var jsonCredentials = JSON.parse(credentials);
        return Api.post('/login', jsonCredentials);
    }
}

export default new AuthApi();