import * as actionTypes from "./actions.types";

export const initialState = {
  courses: [],
  selectedCourse: null,
  isLoading: false,
};

const fetchCoursesRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchCoursesSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    courses: action.payload,
  };
};

const fetchCoursesFailure = (state) => {
  return {
    ...state,
    courses: [],
    isLoading: false,
  };
};

const setSelectedCourse = (state, action) => {
  return {
    ...state,
    selectedCourse: action.payload,
  };
};

const assignNewOrgToCourseRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const assignNewOrgToCourseSuccess = (state, action) => {
  const newAssignedOrg = {
    id: action.payload.organization.id,
    name: action.payload.organization.name,
    language: action.payload.organization.language,
  };

  const updatedSelectedCourse = {
    ...action.payload.course,
    organizations: [...action.payload.course.organizations, newAssignedOrg],
  };

  const filteredCourses = state.courses.filter(
    (course) => course.topicId !== action.payload.course.topicId
  );
  const updatedCourses = [...filteredCourses, updatedSelectedCourse];

  return {
    ...state,
    isLoading: false,
    selectedCourse: updatedSelectedCourse,
    courses: updatedCourses,
  };
};

const assignNewOrgToCourseFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const unassignOrgFromCourseRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const unassignOrgFromCourseSuccess = (state, action) => {
  const filteredOrgs = action.payload.course.organizations.filter(
    (org) => org.id !== action.payload.organization.id
  );

  const updatedSelectedCourse = {
    ...action.payload.course,
    organizations: [...filteredOrgs],
  };

  const filteredCourses = state.courses.filter(
    (course) => course.topicId !== action.payload.course.topicId
  );
  const updatedCourses = [...filteredCourses, updatedSelectedCourse];

  return {
    ...state,
    isLoading: false,
    selectedCourse: updatedSelectedCourse,
    courses: updatedCourses,
  };
};

const unassignOrgFromCourseFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const reducerMap = {
  [actionTypes.FETCH_COURSES_REQUEST]: fetchCoursesRequest,
  [actionTypes.FETCH_COURSES_SUCCESS]: fetchCoursesSuccess,
  [actionTypes.FETCH_COURSES_FAILURE]: fetchCoursesFailure,
  [actionTypes.SET_SELECTED_COURSE]: setSelectedCourse,
  [actionTypes.ASSIGN_NEW_ORG_REQUEST]: assignNewOrgToCourseRequest,
  [actionTypes.ASSIGN_NEW_ORG_SUCCESS]: assignNewOrgToCourseSuccess,
  [actionTypes.ASSIGN_NEW_ORG_FAILURE]: assignNewOrgToCourseFailure,
  [actionTypes.UNASSIGN_ORG_REQUEST]: unassignOrgFromCourseRequest,
  [actionTypes.UNASSIGN_ORG_SUCCESS]: unassignOrgFromCourseSuccess,
  [actionTypes.UNASSIGN_ORG_FAILURE]: unassignOrgFromCourseFailure,
};

const coursesReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default coursesReducer;
