import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonBase } from '@mui/material';
import { MENU_OPEN } from '../../../store/sidebar/actions';
import logo from '../../../assets/images/logo-blue.png';
import './logo.scss';

const Logo = () => {
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={"/"}>
            <div className='logo-section'>
                <img src={logo} className='logo-img' alt='logo' />
            </div>
        </ButtonBase >
    );
};

export default Logo;