import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { fetchProfileDetail, setSelectedProfile } from 'store/profiles/actions';
import ProfileCard from 'views/pages/customer-support/components/ProfileCard';
import MicrolearningData from 'views/pages/customer-support/components/MicrolearningData';
import EmailHistory from 'views/pages/customer-support/components/EmailHistory';
import LoginHistory from 'views/pages/customer-support/components/LoginHistory';
import UserActivityHistory from 'views/pages/customer-support/components/UserActivityHistory';
import MainCard from 'ui/MainCard';
import If from 'ui/If/If';
import { clearHistory } from 'store/profile-history/actions';


const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const Profile = () => {

    const { id } = useParams();
    const profiles = useSelector((state) => state?.profiles?.profiles);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    useEffect(() => {
        if (!id) {
            return navigate("/customersupport");
        }

        if (profiles?.length === 0) {
            dispatch(fetchProfileDetail(id));
        } else {
            const selected = profiles.find(prof => prof.caregiverId === +id);
            dispatch(setSelectedProfile(selected));
        }

        return () => {
            dispatch(clearHistory())
        }
    }, [id])

    return (
        <>
            <ProfileCard />

            <MainCard title="History" sx={{ marginTop: 1 }}>
                <Accordion expanded={expanded === 'panelEmailHistory'} onChange={handleChange('panelEmailHistory')}>
                    <AccordionSummary aria-controls="panel-email-content" id="panel-email-header">
                        <Typography variant='body2'>Email</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <If when={expanded === 'panelEmailHistory'}>
                            <EmailHistory />
                        </If>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panelLoginHistory'} onChange={handleChange('panelLoginHistory')}>
                    <AccordionSummary aria-controls="panel-login-content" id="panel-login-header">
                        <Typography variant='body2'>Login</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <If when={expanded === 'panelLoginHistory'}>
                            <LoginHistory />
                        </If>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panelActivityHistory'} onChange={handleChange('panelActivityHistory')}>
                    <AccordionSummary aria-controls="panel-activity-content" id="panel-activity-header">
                        <Typography variant='body2'>User activity</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <If when={expanded === 'panelActivityHistory'}>
                            <UserActivityHistory />
                        </If>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panelMicrolearningHistory'} onChange={handleChange('panelMicrolearningHistory')}>
                    <AccordionSummary aria-controls="panel-microlearning-content" id="panel-microlearning-header">
                        <Typography variant='body2'>Microlearning data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <If when={expanded === 'panelMicrolearningHistory'}>
                            <MicrolearningData />
                        </If>
                    </AccordionDetails>
                </Accordion>
            </MainCard>
        </>
    )
}

export default Profile