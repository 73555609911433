import * as actionTypes from "./actions.types";

export const initialState = {
  taxonomies: [],
  topics: [],
  filteredTopics: [],
  isLoading: false,
  isTopicUpdated: false,
};

const fetchTopicsRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    isTopicUpdated: false,
  };
};

const fetchTopicsSuccess = (state, action) => {
  let filteredTopics = action.payload;

  if (state?.filter && state?.filter?.length > 0) {
    filteredTopics = action.payload.filter((taxonomy) =>
      state?.filter.includes(taxonomy.language)
    );
  }

  return {
    ...state,
    isLoading: false,
    topics: action.payload,
    filteredTopics,
  };
};

const fetchTopicsFailure = (state) => {
  return {
    ...state,
    topics: [],
    filteredTopics: [],
    isLoading: false,
  };
};

const fetchTaxonomiesRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchTaxonomiesSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    taxonomies: action.payload,
  };
};

const fetchTaxonomiesFailure = (state) => {
  return {
    ...state,
    taxonomies: [],
    isLoading: false,
  };
};


const assignTaxonomyToTopicRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    isTopicUpdated:false,
  };
};

const assignTaxonomyToTopicSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    isTopicUpdated: true,
  };
};

const assignTaxonomyToTopicFailure = (state) => {
  return {
    ...state,
    isLoading: false,
    isTopicUpdated: false,
  };
};


const reducerMap = {
  [actionTypes.FETCH_TOPICS_REQUEST]: fetchTopicsRequest,
  [actionTypes.FETCH_TOPICS_SUCCESS]: fetchTopicsSuccess,
  [actionTypes.FETCH_TOPICS_FAILURE]: fetchTopicsFailure,
  [actionTypes.FETCH_TAXONOMIES_REQUEST]: fetchTaxonomiesRequest,
  [actionTypes.FETCH_TAXONOMIES_SUCCESS]: fetchTaxonomiesSuccess,
  [actionTypes.FETCH_TAXONOMIES_FAILURE]: fetchTaxonomiesFailure,
  [actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_REQUEST]: assignTaxonomyToTopicRequest,
  [actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_SUCCESS]: assignTaxonomyToTopicSuccess,
  [actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_FAILURE]: assignTaxonomyToTopicFailure,
};

const taxonomyReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default taxonomyReducer;
