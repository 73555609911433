import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

const fetchOrganizationsRequest = () => ({
  type: actionTypes.FETCH_ORGANIZATIONS_REQUEST,
});

const fetchOrganizationsSuccess = (payload) => ({
  type: actionTypes.FETCH_ORGANIZATIONS_SUCCESS,
  payload,
});

const fetchOrganizationsFailure = (payload) => ({
  type: actionTypes.FETCH_ORGANIZATIONS_FAILURE,
  payload,
});

export const fetchAllOrganizations = () => {
  const url = "/api/bridge/organization";
  const actions = {
    request: fetchOrganizationsRequest,
    success: fetchOrganizationsSuccess,
    failure: fetchOrganizationsFailure,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Organizations. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

export const filterOrganizations = (searchText) => {
  return {
    type: actionTypes.FILTER_ORGANIZATIONS,
    payload: searchText,
  };
};

const deleteOrganizationRequest = () => ({
  type: actionTypes.DELETE_ORGANIZATION_REQUEST,
});

const deleteOrganizationSuccess = (_result, payload) => ({
  type: actionTypes.DELETE_ORGANIZATION_SUCCESS,
  payload,
});

const deleteOrganizationFailure = (payload) => ({
  type: actionTypes.DELETE_ORGANIZATION_FAILURE,
  payload,
});

export const deleteOrganization = (id) => {
  const url = `/api/bridge/organization/${id}`;

  const actions = {
    request: deleteOrganizationRequest,
    success: deleteOrganizationSuccess,
    failure: deleteOrganizationFailure,
    successParams: id,
  };
  const messages = {
    success: "Organization removed successfully.",
    failure: "Sorry, Unable to Remove Organization. Please try again later.",
  };
  return httpClient.run(Api.delete, url, {}, actions, messages);
};

export const setSelectedOrganization = (payload) => ({
  type: actionTypes.SET_SELECTED_ORGANIZATION,
  payload,
});

export const setClearForm = (payload = false) => ({
  type: actionTypes.SET_CLEAR_FORM,
  payload,
});

const fetchOrganizationRequest = () => ({
  type: actionTypes.FETCH_ORGANIZATION_REQUEST,
});

const fetchOrganizationSuccess = (payload) => ({
  type: actionTypes.FETCH_ORGANIZATION_SUCCESS,
  payload,
});

const fetchOrganizationFailure = (payload) => ({
  type: actionTypes.FETCH_ORGANIZATION_FAILURE,
  payload,
});

export const fetchOrganizationDetail = (id) => {
  const url = `/api/bridge/organization/${id}`;
  const actions = {
    request: fetchOrganizationRequest,
    success: fetchOrganizationSuccess,
    failure: fetchOrganizationFailure,
  };
  return httpClient.run(Api.get, url, {}, actions);
};

const fetchMetricsRequest = () => ({
  type: actionTypes.FETCH_METRICS_REQUEST,
});

const fetchMetricsSuccess = (payload) => ({
  type: actionTypes.FETCH_METRICS_SUCCESS,
  payload,
});

const fetchMetricsFailure = (payload) => ({
  type: actionTypes.FETCH_METRICS_FAILURE,
  payload,
});

export const fetchOrganizationMetrics = (id) => {
  const url = `/api/bridge/metrics/organization/${id}`;
  const actions = {
    request: fetchMetricsRequest,
    success: fetchMetricsSuccess,
    failure: fetchMetricsFailure,
  };
  return httpClient.run(Api.get, url, {}, actions);
};

const addUserRequest = () => ({
  type: actionTypes.ADD_USER_REQUEST,
});

const addUserSuccess = (payload) => ({
  type: actionTypes.ADD_USER_SUCCESS,
  payload,
});

const addUserFailure = (payload) => ({
  type: actionTypes.ADD_USER_FAILURE,
  payload,
});

export const addUserToOrganization = (id, orgName) => {
  const url = `/api/bridge/organization/action/addMe/${id}`;
  const actions = {
    request: addUserRequest,
    success: addUserSuccess,
    failure: addUserFailure,
  };
  const messages = {
    success: `You are added to "${orgName}" successfully.`,
    failure: `Sorry, Unable to add you to "${orgName}". Please try again later.`,
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};

const removeUserRequest = () => ({
  type: actionTypes.REMOVE_USER_REQUEST,
});

const removeUserSuccess = (payload) => ({
  type: actionTypes.REMOVE_USER_SUCCESS,
  payload,
});

const removeUserFailure = (payload) => ({
  type: actionTypes.REMOVE_USER_FAILURE,
  payload,
});

export const removeUserFromOrganization = (
  userId,
  userName,
  orgId,
  orgName
) => {
  const url = `/api/bridge/organization/action/removeUser/${userId}/org/${orgId}`;
  const actions = {
    request: removeUserRequest,
    success: removeUserSuccess,
    failure: removeUserFailure,
  };
  const messages = {
    success: `You are removed "${userName}" from "${orgName}" successfully.`,
    failure: `Sorry, Unable to remove "${userName}" from "${orgName}". Please try again later.`,
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};

export const removeMeFromOrganization = (id, orgName) => {
  const url = `/api/bridge/organization/action/removeMe/${id}`;
  const actions = {
    request: removeUserRequest,
    success: removeUserSuccess,
    failure: removeUserFailure,
  };
  const messages = {
    success: `You are removed from "${orgName}" successfully.`,
    failure: `Sorry, Unable to remove you from "${orgName}". Please try again later.`,
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};

const addOrganizationRequest = () => ({
  type: actionTypes.ADD_ORGANIZATION_REQUEST,
});

const addOrganizationSuccess = (payload) => ({
  type: actionTypes.ADD_ORGANIZATION_SUCCESS,
  payload,
});

const addOrganizationFailure = (payload) => ({
  type: actionTypes.ADD_ORGANIZATION_FAILURE,
  payload,
});

export const addNewOrganization = (data) => {
  const url = `/api/bridge/organization`;
  const actions = {
    request: addOrganizationRequest,
    success: addOrganizationSuccess,
    failure: addOrganizationFailure,
  };
  const messages = {
    success: `"${data.name}" added successfully.`,
    failure: `Sorry, Unable to add "${data.name}". Please try again later.`,
  };
  return httpClient.run(Api.post, url, data, actions, messages);
};

const updateOrganizationRequest = () => ({
  type: actionTypes.UPDATE_ORGANIZATION_REQUEST,
});

const updateOrganizationSuccess = (payload) => ({
  type: actionTypes.UPDATE_ORGANIZATION_SUCCESS,
  payload,
});

const updateOrganizationFailure = (payload) => ({
  type: actionTypes.UPDATE_ORGANIZATION_FAILURE,
  payload,
});

export const updateOrganization = (data) => {
  const url = `/api/bridge/organization`;
  const actions = {
    request: updateOrganizationRequest,
    success: updateOrganizationSuccess,
    failure: updateOrganizationFailure,
  };
  const messages = {
    success: `"${data.name}" updated successfully.`,
    failure: `Sorry, Unable to update "${data.name}". Please try again later.`,
  };
  return httpClient.run(Api.put, url, data, actions, messages);
};
