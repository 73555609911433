import * as React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import LoadingSkeleton from "ui/LoadingSkeleton/LoadingSkeleton";

const columns = [
    {
        field: "caregiverId",
        headerName: "#ID",
        headerAlign: "center",
        align: "center",
        width: 80,
        flex: 0.125,
    },
    {
        field: "firstName",
        headerName: "First Name",
        headerAlign: "center",
        align: "center",
        width: 90,
        flex: 0.125,
    },
    {
        field: "lastName",
        headerName: "Last Name",
        headerAlign: "center",
        align: "center",
        width: 90,
        flex: 0.125,
    },
    {
        field: "primaryOrganizationName",
        headerName: "Primary Organization",
        headerAlign: "center",
        align: "center",
        width: 130,
        flex: 0.125,
    },
    {
        field: "hasManyOrganizations",
        headerName: "Many Organizations",
        headerAlign: "center",
        align: "center",
        width: 200,
        flex: 0.2,
        valueGetter: (params) => {
            return params.value ? "✔" : "❌";
        }
    },
    {
        field: "role",
        headerName: "Role",
        headerAlign: "center",
        align: "center",
        width: 70,
        flex: 0.075
    },
    {
        field: "isEnabled",
        headerName: "Status",
        headerAlign: "center",
        align: "center",
        width: 100,
        flex: 0.1,
        valueGetter: (params) => {
            return params.value ? "✔" : "❌";
        }
    },
];

const ProfilesTable = () => {
    const navigate = useNavigate();
    const profiles = useSelector((state) => state?.profiles?.profiles);
    const isLoading = useSelector((state) => state?.profiles?.isLoading);

    return (
        <Box sx={{ minHeight: 550, width: '100%' }}>
            <DataGrid autoHeight
                getRowId={(row) => row.caregiverId}
                rows={isLoading ? [] : profiles}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
                components={{
                    LoadingOverlay: LoadingSkeleton,
                }}
                loading={isLoading}
                onRowSelectionModelChange={(caregiverId) => {
                    navigate(`${caregiverId}`)
                }}
            />
        </Box>
    );
};

export default ProfilesTable;
