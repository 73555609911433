import * as icons from "@tabler/icons-react";

const dashboard = {
  id: "dashboard",
  title: "Dashboard",
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
  ],
};

const pages = {
  id: "pages",
  title: "Pages",
  caption: "",
  type: "group",
  children: [
    {
      id: "customer-support",
      title: "Customer Support",
      type: "item",
      url: "/customersupport",
      icon: icons.IconHelpHexagon,
    },
    {
      id: "organization-management",
      title: "Organizations",
      type: "item",
      url: "/organizations",
      icon: icons.IconBuildingSkyscraper,
    },
    {
      id: "microlearning",
      title: "Microlearning",
      type: "collapse",
      icon: icons.IconSchool,
      children: [
        {
          id: "course-management",
          title: "Course Management",
          type: "item",
          url: "/micro/courses",
          icon: icons.IconBooks,
          breadcrumbs: false,
        },
        {
          id: "tag-managemnt",
          title: "Tag Management",
          type: "item",
          url: "/micro/tags",
          icon: icons.IconTags,
          breadcrumbs: false,
        },
        {
          id: "taxonomy",
          title: "Taxonomy",
          type: "item",
          url: "/micro/taxonomy",
          icon: icons.IconAffiliate,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "operations",
      title: "Operations",
      type: "collapse",
      icon: icons.IconSettingsAutomation,
      children: [
        {
          id: "actions",
          title: "actions",
          type: "item",
          url: "/operations/actions",
          icon: icons.IconApps,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

const menuItems = {
  items: [dashboard, pages],
};

export default menuItems;
