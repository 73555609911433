export const SET_USER_INFO = "@LOGIN/SET_USER_INFO";
export const CLEAR_USER_INFO = "@LOGIN/CLEAR_USER_INFO";

/**
 * store logged in user profile in the global state management
 * @param {object} payload {name: string, email: string, avatar: imageUrl} 
 * @returns 
 */
export const setUserInfo = (payload) => ({
  type: SET_USER_INFO,
  payload,
});

export const clearUserInfo = () => ({
  type: CLEAR_USER_INFO
});