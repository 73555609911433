import { combineReducers } from "redux";

import customizationReducer from "./sidebar/sideBarReducer";
import profilesReducer from "./profiles/profilesReducer";
import confirmReducer from "./confirm/confirmReducer";
import alertReducer from "./alert/alertReducer";
import historyReducer from "./profile-history/historyReducer";
import organizationsReducer from "./organizations/organizationsReducer";
import coursesReducer from "./courses/coursesReducer";
import tagsReducer from "./tags/tagsReducer";
import taxonomyReducer from "./taxonomy/taxonomyReducer";
import loginReducer from "./login/loginReducer";
import dashboardReducer from "./dashboard/dashboardReducer";
import operationsReducer from "./operations/operationsReducer";

const reducer = combineReducers({
  customization: customizationReducer,
  profiles: profilesReducer,
  confirm: confirmReducer,
  alert: alertReducer,
  history: historyReducer,
  organizations: organizationsReducer,
  courses: coursesReducer,
  tags: tagsReducer,
  taxonomy: taxonomyReducer,
  login: loginReducer,
  dashboard: dashboardReducer,
  operations: operationsReducer,
});

export default reducer;
