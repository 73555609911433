import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { fetchMicrolearning } from 'store/profile-history/actions';

const MicrolearningData = () => {

  const { id } = useParams();
  const selectedId = useSelector((state) => state?.history?.id);
  const microlearning = useSelector((state) => state?.history?.microlearning);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedId !== id || !microlearning) {
      dispatch(fetchMicrolearning(id))
    }
  }, [id, selectedId])

  return (
    <Box p={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} >
          <Typography variant="body2">
            <b>Bites finished in the last 7 days :</b> {microlearning?.bitesFinishedLast7Days}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Typography variant="body2">
            <b>Bites finished in the last 30 days :</b> {microlearning?.bitesFinishedLast28Days}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Typography variant="body2">
            <b>All bites finished:</b> {microlearning?.allBitesFinished}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Typography variant="body2">
            <b>All topics finished:</b> {microlearning?.allTopicsFinished}
          </Typography>
        </Grid>
      </Grid>
      <Divider sx={{ margin: '1rem 0' }} />
      <Grid container spacing={2}>
        <Grid item xs={12} >
          <Typography variant="h5">
            Recommendation:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            <b>Bite title: </b>
            {microlearning?.nbRecommendations?.biteTitle} (ID: {microlearning?.nbRecommendations?.biteId})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">
            <b>Topic title: </b>
            {microlearning?.nbRecommendations?.topicTitle} (ID: {microlearning?.nbRecommendations?.topicId})
          </Typography>
        </Grid>
      </Grid>
    </Box >
  )
}

export default MicrolearningData;