import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

export const clearHistory = () => ({
  type: actionTypes.CLEAR_HISTORY,
});

//========EMAIL HISTORY========
const fetchEmailRequest = () => ({
  type: actionTypes.FETCH_EMAIL_REQUEST,
});

const fetchEmailSuccess = (emails, id) => ({
  type: actionTypes.FETCH_EMAIL_SUCCESS,
  payload: { id, emails },
});

const fetchEmailFailure = (message, id) => ({
  type: actionTypes.FETCH_EMAIL_FAILURE,
  payload: { id, message },
});

export const fetchUserEmails = (id) => {
  const url = `/api/bridge/user/${id}/emails`;
  const actions = {
    request: fetchEmailRequest,
    success: fetchEmailSuccess,
    successParams: id,
    failure: fetchEmailFailure,
    failureParams: id,
  };
  const messages = {
    failure:
      "Sorry, Unable to Retrieve Emails History. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

//========LOGIN HISTORY========
const fetchLoginRequest = () => ({
  type: actionTypes.FETCH_LOGIN_REQUEST,
});

const fetchLoginSuccess = (logins, id) => ({
  type: actionTypes.FETCH_LOGIN_SUCCESS,
  payload: { id, logins },
});

const fetchLoginFailure = (message, id) => ({
  type: actionTypes.FETCH_LOGIN_FAILURE,
  payload: { id, message },
});

export const fetchUserLogins = (id) => {
  const url = `/api/bridge/user/${id}/loginHistory`;
  const actions = {
    request: fetchLoginRequest,
    success: fetchLoginSuccess,
    successParams: id,
    failure: fetchLoginFailure,
    failureParams: id,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Login History. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

//========ACTIVITY HISTORY========
const fetchActivityRequest = () => ({
  type: actionTypes.FETCH_ACTIVITY_REQUEST,
});

const fetchActivitySuccess = (activities, id) => ({
  type: actionTypes.FETCH_ACTIVITY_SUCCESS,
  payload: { id, activities },
});

const fetchActivityFailure = (message, id) => ({
  type: actionTypes.FETCH_ACTIVITY_FAILURE,
  payload: { id, message },
});

export const fetchUserActivities = (id) => {
  const url = `/api/bridge/user/${id}/activity`;
  const actions = {
    request: fetchActivityRequest,
    success: fetchActivitySuccess,
    successParams: id,
    failure: fetchActivityFailure,
    failureParams: id,
  };
  const messages = {
    failure:
      "Sorry, Unable to Retrieve User Activities. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

//========MICROLEARNING HISTORY========
const fetchMicrolearningRequest = () => ({
  type: actionTypes.FETCH_MICROLEARNING_REQUEST,
});

const fetchMicrolearningSuccess = (data, id) => ({
  type: actionTypes.FETCH_MICROLEARNING_SUCCESS,
  payload: { id, data },
});

const fetchMicrolearningFailure = (message, id) => ({
  type: actionTypes.FETCH_MICROLEARNING_FAILURE,
  payload: { id, message },
});

export const fetchMicrolearning = (id) => {
  const url = `/api/bridge/user/${id}/learning`;
  const actions = {
    request: fetchMicrolearningRequest,
    success: fetchMicrolearningSuccess,
    successParams: id,
    failure: fetchMicrolearningFailure,
    failureParams: id,
  };
  const messages = {
    failure:
      "Sorry, Unable to Retrieve Microlearning Data. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};
