import axios from "axios";
import Auth from "../services/Auth";

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const Api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "X-Client-Version": "1.0-Snapshot",
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use((config) => {
  if (Auth.isLoggedIn()) {
    const authHeader = Auth.getAuthorizationHeader();
    config.headers["authorization"] = authHeader;
  }

  return config;
});

Api.interceptors.response.use(
  (r) => r,
  (err) => {
    if (
      err.response &&
      (err.response.status === 401 || err.response.status === 403)
    ) {
      Auth.logout();
      Promise.reject(err);
    } else {
      Promise.reject(err);
    }

    return Promise.reject(err);
  }
);
window.Api = Api;

export default Api;
