import { useDispatch } from 'react-redux';
import { Grid, Paper, Stack, Button } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { fetchAllOrganizations, filterOrganizations } from 'store/organizations/actions';
import OrganizationsTable from './components/OrganizationsTable';
import SearchBox from 'ui/SearchBox/SearchBox';

function Organizations() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchAllOrganizations());
    }, [])


    const onSearchChange = (searchText) => {
        dispatch(filterOrganizations(searchText));
    }

    const handleRedirect = () => {
        navigate("new")
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper sx={{ padding: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Grid item xs={7} sm={8} md={9} lg={10}>
                            <SearchBox placeholder={"Search Organization"} handleChange={onSearchChange} />
                        </Grid>
                        <Grid item xs={5} sm={4} md={3} lg={2}>
                            <Button variant="contained" sx={{ height: '48px', borderRadius: '10px' }} onClick={handleRedirect}>
                                Add Organization
                            </Button>
                        </Grid>
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper sx={{ padding: 2 }}>
                    <OrganizationsTable />
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Organizations;