export const CLEAR_HISTORY = "@HISTORY/CLEAR_HISTORY";
export const FETCH_EMAIL_REQUEST = "@HISTORY/FETCH_EMAIL_REQUEST";
export const FETCH_EMAIL_SUCCESS = "@HISTORY/FETCH_EMAIL_SUCCESS";
export const FETCH_EMAIL_FAILURE = "@HISTORY/FETCH_EMAIL_FAILURE";
export const FETCH_LOGIN_REQUEST = "@HISTORY/FETCH_LOGIN_REQUEST";
export const FETCH_LOGIN_SUCCESS = "@HISTORY/FETCH_LOGIN_SUCCESS";
export const FETCH_LOGIN_FAILURE = "@HISTORY/FETCH_LOGIN_FAILURE";
export const FETCH_ACTIVITY_REQUEST = "@HISTORY/FETCH_ACTIVITY_REQUEST";
export const FETCH_ACTIVITY_SUCCESS = "@HISTORY/FETCH_ACTIVITY_SUCCESS";
export const FETCH_ACTIVITY_FAILURE = "@HISTORY/FETCH_ACTIVITY_FAILURE";
export const FETCH_MICROLEARNING_REQUEST =
  "@HISTORY/FETCH_MICROLEARNING_REQUEST";
export const FETCH_MICROLEARNING_SUCCESS =
  "@HISTORY/FETCH_MICROLEARNING_SUCCESS";
export const FETCH_MICROLEARNING_FAILURE =
  "@HISTORY/FETCH_MICROLEARNING_FAILURE";
