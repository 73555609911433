import * as actionTypes from "./actions.types";

export const initialState = {
  id: null,
  isLoading: false,
  emails: [],
  logins: [],
  activities: null,
  microlearning: null,
};

const emailRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const emailSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    emails: action.payload.emails,
    id: action.payload.id,
  };
};

const emailFailure = (state, action) => {
  return {
    ...state,
    emails: [],
    isLoading: false,
    id: action.payload.id,
  };
};

const loginRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const loginSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    logins: action.payload.logins,
    id: action.payload.id,
  };
};

const loginFailure = (state, action) => {
  return {
    ...state,
    logins: [],
    isLoading: false,
    id: action.payload.id,
  };
};

const activityRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const activitySuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    activities: action.payload.activities,
    id: action.payload.id,
  };
};

const activityFailure = (state, action) => {
  return {
    ...state,
    activities: null,
    isLoading: false,
    id: action.payload.id,
  };
};
const microlearningRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const microlearningSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    microlearning: action.payload.data,
    id: action.payload.id,
  };
};

const microlearningFailure = (state, action) => {
  return {
    ...state,
    microlearning: null,
    isLoading: false,
    id: action.payload.id,
  };
};

const reducerMap = {
  [actionTypes.CLEAR_HISTORY]: () => initialState,
  [actionTypes.FETCH_EMAIL_REQUEST]: emailRequest,
  [actionTypes.FETCH_EMAIL_SUCCESS]: emailSuccess,
  [actionTypes.FETCH_EMAIL_FAILURE]: emailFailure,
  [actionTypes.FETCH_LOGIN_REQUEST]: loginRequest,
  [actionTypes.FETCH_LOGIN_SUCCESS]: loginSuccess,
  [actionTypes.FETCH_LOGIN_FAILURE]: loginFailure,
  [actionTypes.FETCH_ACTIVITY_REQUEST]: activityRequest,
  [actionTypes.FETCH_ACTIVITY_SUCCESS]: activitySuccess,
  [actionTypes.FETCH_ACTIVITY_FAILURE]: activityFailure,
  [actionTypes.FETCH_MICROLEARNING_REQUEST]: microlearningRequest,
  [actionTypes.FETCH_MICROLEARNING_SUCCESS]: microlearningSuccess,
  [actionTypes.FETCH_MICROLEARNING_FAILURE]: microlearningFailure,
};

const historyReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default historyReducer;
