import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Button, Grid, Stack, Typography } from '@mui/material';
import { IconUserPlus, IconUserX, IconEdit, IconMoodHeart } from '@tabler/icons-react';

import MainCard from 'ui/MainCard';
import { addUserToOrganization, removeMeFromOrganization } from 'store/organizations/actions';
import { searchProfiles } from 'store/profiles/actions';

const OrganizationCard = () => {
    const organization = useSelector((state) => state?.organizations?.selectedOrganization);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleEdit = () => {
        return navigate('edit');
    }

    const handleAddUser = () => {
        dispatch(addUserToOrganization(organization.id, organization.name));
    }
    const handleRemoveUser = () => {
        dispatch(removeMeFromOrganization(organization.id, organization.name));
    }

    const handleShowCaregivers = () => {
        dispatch(searchProfiles(`Orgid:${organization.id}`));
        navigate('/customersupport');
    }

    return (
        <MainCard title={organization?.name}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        <b>Language:</b> {organization?.language}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        <b>Type:</b> {organization?.type}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        <b>Authentication session :</b> {organization?.sessionTimeInMinutes} minutes  (Auto log out inactive users: {organization?.inactivityLogOut ? 'Yes' : 'No'} )
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">
                        <b>The maximum validity of the authentication token:</b> {organization?.refreshTokenTimeInMinutes} minutes
                    </Typography>
                </Grid>

                <Grid item xs={12} marginTop={2}>
                    <Stack direction={{ xs: "column", sm: "row" }}
                        justifyContent="flex-start"
                        spacing={{ xs: 1, sm: 2 }}>
                        <Button variant="outlined" color="primary" onClick={handleEdit}>
                            <IconEdit stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Edit
                        </Button>
                        <Button variant="outlined" color="success" onClick={handleAddUser}>
                            <IconUserPlus stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} /> Add me
                        </Button>
                        <Button variant="contained" color="error" onClick={handleRemoveUser}>
                            <IconUserX stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} />Remove me
                        </Button>
                        <Divider orientation="vertical" variant="middle" flexItem
                            size="1" mx={1}
                        />
                        <Button variant="contained" color="secondary" onClick={handleShowCaregivers}>
                            <IconMoodHeart stroke={1.75} size="1.2rem" style={{ marginRight: "0.4rem" }} />Show Caregivers
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </MainCard >
    );
}

export default OrganizationCard;