import React from "react";
import LanguageToggleButtons from "ui/LanguageToggleButtons/LanguageToggleButtons";
import { Button, Grid, Paper, Stack } from "@mui/material";
import TagsTable from "./components/TagsTable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchAllTags, setFilter, setSelectedTag, showTagModal } from "store/tags/actions";
import TagForm from "./modals/TagForm";

const TagManagement = () => {
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(fetchAllTags());
    }, [])

    const filterHandler = (filter) => {
        dispatch(setFilter(filter))
    }

    const newTagHandler = () => {
        dispatch(setSelectedTag(null))
        dispatch(showTagModal())
    }

    return (
        <>
            <TagForm />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <LanguageToggleButtons
                                changeHandler={filterHandler}
                            />
                            <Button variant="contained"
                                sx={{ borderRadius: '10px' }}
                                onClick={newTagHandler}>
                                Add New
                            </Button>
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <TagsTable />
                    </Paper>
                </Grid>
            </Grid>
        </>

    )
}

export default TagManagement;