import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

const fetchCoursesRequest = () => ({
  type: actionTypes.FETCH_COURSES_REQUEST,
});

const fetchCoursesSuccess = (payload) => ({
  type: actionTypes.FETCH_COURSES_SUCCESS,
  payload,
});

const fetchCoursesFailure = (payload) => ({
  type: actionTypes.FETCH_COURSES_FAILURE,
  payload,
});

export const fetchAllCourses = () => {
  const url = "/api/bridge/microlearning/topics";
  const actions = {
    request: fetchCoursesRequest,
    success: fetchCoursesSuccess,
    failure: fetchCoursesFailure,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Courses. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

export const setSelectedCourse = (payload) => ({
  type: actionTypes.SET_SELECTED_COURSE,
  payload,
});

const assignNewOrganizationRequest = () => ({
  type: actionTypes.ASSIGN_NEW_ORG_REQUEST,
});

const assignNewOrganizationSuccess = (_result, payload) => ({
  type: actionTypes.ASSIGN_NEW_ORG_SUCCESS,
  payload,
});

const assignNewOrganizationFailure = (payload) => ({
  type: actionTypes.ASSIGN_NEW_ORG_FAILURE,
  payload,
});

export const assignNewOrganization = (course, organization) => {
  const url = `/api/bridge/microlearning/topics/organization/${organization.id}/topic/${course.topicId}`;

  const actions = {
    request: assignNewOrganizationRequest,
    success: assignNewOrganizationSuccess,
    failure: assignNewOrganizationFailure,
    successParams: { course, organization },
  };
  const messages = {
    success: `${organization.name} assigned to ${course.title} successfully.`,
    failure: "Sorry, Unable to Retrieve Courses. Please try again later.",
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};

const unassignOrganizationRequest = () => ({
  type: actionTypes.UNASSIGN_ORG_REQUEST,
});

const unassignOrganizationSuccess = (_result, payload) => ({
  type: actionTypes.UNASSIGN_ORG_SUCCESS,
  payload,
});

const unassignOrganizationFailure = (payload) => ({
  type: actionTypes.UNASSIGN_ORG_FAILURE,
  payload,
});

export const unassignOrganization = (course, organization) => {
  const url = `/api/bridge/microlearning/topics/organization/${organization.id}/topic/${course.topicId}`;

  const actions = {
    request: unassignOrganizationRequest,
    success: unassignOrganizationSuccess,
    failure: unassignOrganizationFailure,
    successParams: { course, organization },
  };
  const messages = {
    success: `${organization.name} unassigned to ${course.title} successfully.`,
    failure: "Sorry, Unable to Retrieve Courses. Please try again later.",
  };
  return httpClient.run(Api.delete, url, {}, actions, messages);
};
