import { Navigate } from "react-router";
import Auth from "../services/Auth";
import { showErrorAlert, showSuccessAlert } from "../store/alert/actions";

/**
 *
 * @param {Function} func  api Method
 * @param {string} url  api url
 * @param {object} params  parameters
 * @param {object} actions {request?: requestAction, success: successAction, failure: failureAction, successParams?: any, failureParams?: any}
 * @param {object} messages {success?: string, failure?: string}
 * @returns
 */
export const run = (func, url, params, actions, messages) => {
  return async (dispatch) => {
    if (actions?.request) {
      dispatch(actions.request());
    }

    try {
      const response = await func(url, params);

      if (actions?.success) {
        dispatch(actions.success(response.data, actions?.successParams));
      }

      if (messages?.success) {
        dispatch(showSuccessAlert(messages.success));
      }
    } catch (error) {
      if (actions?.failure) {
        dispatch(actions.failure(error, actions?.failureParams));
      }

      if (messages?.failure) {
        dispatch(showErrorAlert(messages.failure));
      }

      if (error.response.status === 401 || error.response.status === 403) {
        Auth.logout();
      }
    }
  };
};
