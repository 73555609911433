import * as actionTypes from "./actions.type";

export const initialState = {
  isLoading: false,
  profiles: [],
  selectedProfile: null,
};

const searchRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const searchSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    profiles: action.payload,
  };
};

const searchFailed = (state, action) => {
  return {
    ...state,
    profiles: [],
    isLoading: false,
  };
};

const setSelectedProfile = (state, action) => {
  return {
    ...state,
    selectedProfile: action.payload,
  };
};

const updateProfileInfo = (state, action) => {
  const filteredProfiles = state.profiles.filter(
    (prof) => prof.caregiverId !== action.payload.caregiverId
  );

  return {
    ...state,
    isLoading: false,
    profiles: [...filteredProfiles, action.payload],
    selectedProfile: action.payload,
  };
};

const fetchProfileRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const fetchProfileSuccess = (state, action) => {
  return {
    ...state,
    isLoading: false,
    profiles: [action.payload],
    selectedProfile: action.payload,
  };
};

const fetchProfileFailed = (state, action) => {
  return {
    ...state,
    profiles: [],
    selectedProfile: null,
    isLoading: false,
  };
};

const reducerMap = {
  [actionTypes.SEARCH_PROFILES_REQUEST]: searchRequest,
  [actionTypes.SEARCH_PROFILES_SUCCESS]: searchSuccess,
  [actionTypes.SEARCH_PROFILES_FAILURE]: searchFailed,
  [actionTypes.FETCH_PROFILE_REQUEST]: fetchProfileRequest,
  [actionTypes.FETCH_PROFILE_SUCCESS]: fetchProfileSuccess,
  [actionTypes.FETCH_PROFILE_FAILURE]: fetchProfileFailed,
  [actionTypes.SET_SELECTED_PROFILE]: setSelectedProfile,
  [actionTypes.UPDATE_PROFILE_INFO]: updateProfileInfo,
};

const profilesReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default profilesReducer;
