import { IconArrowNarrowRight, IconTrendingDown } from "@tabler/icons-react";
import { IconTrendingUp } from "@tabler/icons-react";

export const getChipData = (diff) => {
  if (diff > 0) {
    return {
      color: "success",
      icon: <IconTrendingUp size={15} />,
    };
  } else if (diff < 0) {
    return {
      color: "error",
      icon: <IconTrendingDown size={15} />,
    };
  } else {
    return {
      color: "info",
      icon: <IconArrowNarrowRight size={15} />,
    };
  }
};
