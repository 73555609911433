import { compose, createStore, applyMiddleware } from "redux";
import reducer from "./reducer";
import thunk from "redux-thunk";
import logger from "redux-logger";

const middlewares = [logger, thunk];
const cpmposedEnhancers = compose(applyMiddleware(...middlewares));

const store = createStore(reducer, undefined, cpmposedEnhancers);
export { store };
