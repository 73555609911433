import { useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Chip,
  Tooltip
} from "@mui/material";
import {
  IconLogout,
} from "@tabler/icons-react";
import { useNavigate } from "react-router";
import Auth from "services/Auth";

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const userProfile = useSelector((state) => state.login);

  const handleLogout = (event) => {
    event.preventDefault();
    Auth.logout();
    navigate("./bridgeapplication");
  };

  return (
    <Chip
      sx={{
        height: "48px",
        alignItems: "center",
        borderRadius: "27px",
        transition: "all .2s ease-in-out",
        borderColor: theme.palette.primary.light,
        backgroundColor: theme.palette.primary.light,
        '&[aria-controls="menu-list-grow"], &:hover': {
          borderColor: theme.palette.primary.main,
          background: `${theme.palette.primary.main}!important`,
          color: theme.palette.primary.light,
          "& svg": {
            stroke: theme.palette.primary.light,
          },
        },
        "& .MuiChip-label": {
          lineHeight: 0,
        },
      }}
      icon={
        <Tooltip title={userProfile.name}>
          <Avatar
            src={userProfile.avatar}
            sx={{
              ...theme.typography.mediumAvatar,
              margin: "8px 0 8px 8px !important",
            }}
            color='inherit'
          />
        </Tooltip>
      }
      label={
        <Tooltip title="Logout">
          <IconLogout
            stroke={1.5}
            size='1.5rem'
            color={theme.palette.primary.main}
            cursor={"pointer"}
            onClick={handleLogout}
          />
        </Tooltip>
      }
      variant='outlined'
      color='primary'
    />
  );
};

export default Profile;
