import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Paper, Stack } from "@mui/material";
import TaxonomiesTable from "./components/TaxonomiesTable";
import LanguageToggleButtons from "ui/LanguageToggleButtons/LanguageToggleButtons";
import { fetchTaxonomies, fetchTopics } from "store/taxonomy/actions";

const TaxonomyManagement = () => {
    const dispatch = useDispatch();
    const needsToRefresh = useSelector((state) => state?.taxonomy?.isTopicUpdated);
    const [filter, setFilter] = useState("sv");

    useEffect(() => {
        dispatch(fetchTaxonomies(filter));
        dispatch(fetchTopics(filter));
    }, [])

    const filterHandler = (newFilter) => {
        setFilter(newFilter);
        dispatch(fetchTaxonomies(newFilter));
        dispatch(fetchTopics(newFilter));
    }


    useEffect(() => {
        if (!needsToRefresh) {
            return;
        }

        dispatch(fetchTopics(filter));
    }, [needsToRefresh]);



    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <LanguageToggleButtons
                                changeHandler={filterHandler}
                                ismultiSelect={false}
                            />
                        </Stack>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper sx={{ padding: 2 }}>
                        <TaxonomiesTable />
                    </Paper>
                </Grid>
            </Grid>
        </>

    )
}

export default TaxonomyManagement;