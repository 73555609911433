import { useEffect, useState } from "react"
import { useDispatch } from "react-redux";
import { FormControl, InputLabel, MenuItem, ToggleButton, Select, Stack, ToggleButtonGroup, Typography } from "@mui/material";
import { fetchChartValues } from "store/dashboard/actions";
import "./MetricsFilter.scss";

const filters = [{
    id: "week",
    label: "Weekly"
},
{ id: "month", label: "Monthly" },
{ id: "quarter", label: "Quarterly" },
{ id: "halfyear", label: "Half-yearly" },
]

const MetricsFilter = () => {
    const dispatch = useDispatch();
    const [type, setType] = useState("user");
    const [filter, setFilter] = useState("quarter");

    const handleChange = (_, newFilter) => {
        setFilter(newFilter);
    };

    useEffect(() => {
        if (!type && !filter) {
            return;
        }

        dispatch(fetchChartValues(filter, type));
    }, [type, filter]);

    const typeDropDown = (
        <div>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="cbo-metrics-label">Metrics</InputLabel>
                <Select
                    labelId="cbo-metrics-label"
                    id="cbo-metrics"
                    value={type}
                    label="Metrics"
                    onChange={e => setType(e.target.value)}
                >
                    <MenuItem value="user">User Data</MenuItem>
                    <MenuItem value="message">Messages Data</MenuItem>
                </Select>
            </FormControl>
        </div>
    );

    const filterButtons = (
        <ToggleButtonGroup
            color="info"
            exclusive
            sx={{ height: "40px" }}
            onChange={handleChange}
            value={filter}>
            {
                filters.map(f => (
                    <ToggleButton value={f.id} key={f.id} size="small">
                        <Typography variant="body2">
                            {f.label}
                        </Typography>
                    </ToggleButton>
                ))
            }
        </ToggleButtonGroup >
    );

    return (
        <Stack className="metrics-filter" direction="row" justifyContent="space-between" alignItems="center">
            {typeDropDown}
            {filterButtons}
        </Stack>
    )
}

export default MetricsFilter;