export const FETCH_COURSES_REQUEST = "@COURSES/FETCH_COURSES_REQUEST";
export const FETCH_COURSES_SUCCESS = "@COURSES/FETCH_COURSES_SUCCESS";
export const FETCH_COURSES_FAILURE = "@COURSES/FETCH_COURSES_FAILURE";
export const SET_SELECTED_COURSE = "@COURSES/SET_SELECTED_COURSE";
export const ASSIGN_NEW_ORG_REQUEST = "@COURSES/ASSIGN_NEW_ORG_REQUEST";
export const ASSIGN_NEW_ORG_SUCCESS = "@COURSES/ASSIGN_NEW_ORG_SUCCESS";
export const ASSIGN_NEW_ORG_FAILURE = "@COURSES/ASSIGN_NEW_ORG_FAILURE";
export const UNASSIGN_ORG_REQUEST = "@COURSES/UNASSIGN_ORG_REQUEST";
export const UNASSIGN_ORG_SUCCESS = "@COURSES/UNASSIGN_ORG_SUCCESS";
export const UNASSIGN_ORG_FAILURE = "@COURSES/UNASSIGN_ORG_FAILURE";
