import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

const fetchTopicsRequest = () => ({
  type: actionTypes.FETCH_TOPICS_REQUEST,
});

const fetchTopicsSuccess = (payload) => ({
  type: actionTypes.FETCH_TOPICS_SUCCESS,
  payload,
});

const fetchTopicsFailure = (payload) => ({
  type: actionTypes.FETCH_TOPICS_FAILURE,
  payload,
});

export const fetchTopics = (language) => {
  const url = `/api/bridge/microlearning/topicswithtaxonomy/${language}`;
  const actions = {
    request: fetchTopicsRequest,
    success: fetchTopicsSuccess,
    failure: fetchTopicsFailure,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Topics. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

const fetchTaxonomiesRequest = () => ({
  type: actionTypes.FETCH_TAXONOMIES_REQUEST,
});

const fetchTaxonomiesSuccess = (payload) => ({
  type: actionTypes.FETCH_TAXONOMIES_SUCCESS,
  payload,
});

const fetchTaxonomiesFailure = (payload) => ({
  type: actionTypes.FETCH_TAXONOMIES_FAILURE,
  payload,
});

export const fetchTaxonomies = (language) => {
  const url = `/api/bridge/microlearning/taxonomy/${language}`;
  const actions = {
    request: fetchTaxonomiesRequest,
    success: fetchTaxonomiesSuccess,
    failure: fetchTaxonomiesFailure,
  };
  const messages = {
    failure: "Sorry, Unable to Retrieve Taxonomies. Please try again later.",
  };
  return httpClient.run(Api.get, url, {}, actions, messages);
};

//====ASSIGN TAXONOMY TO TOPIC=====
const assignTaxonomyToTopicRequest = () => ({
  type: actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_REQUEST,
});

const assignTaxonomyToTopicSuccess = (payload) => ({
  type: actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_SUCCESS,
  payload,
});

const assignTaxonomyToTopicFailure = (payload) => ({
  type: actionTypes.ASSIGN_TAXONOMY_TO_TOPIC_FAILURE,
  payload,
});

export const assignTopicToTaxonomy = (topicId, topicName, taxoId, taxoName) => {
  const url = `/api/bridge/microlearning/topicswithtaxonomy/${topicId}/${taxoId}`;
  const actions = {
    request: assignTaxonomyToTopicRequest,
    success: assignTaxonomyToTopicSuccess,
    failure: assignTaxonomyToTopicFailure,
  };
  const messages = {
    success: ` ${taxoName} assigned to ${topicName} successfully.`,
    failure: "Sorry, Unable to Assign. Please try again later.",
  };
  return httpClient.run(Api.post, url, {}, actions, messages);
};
