import Api from "api/Api";
import * as httpClient from "api/httpClient";
import * as actionTypes from "./actions.types";

//======METRICS CARDS======
const fetchMetricsRequest = () => ({
  type: actionTypes.FETCH_METRICS_REQUEST,
});

const fetchMetricsSuccess = (payload) => ({
  type: actionTypes.FETCH_METRICS_SUCCESS,
  payload,
});

const fetchMetricsFailure = (payload) => ({
  type: actionTypes.FETCH_METRICS_FAILURE,
  payload,
});

export const fetchMetrics = () => {
  const url = "/api/bridge/metrics/";
  const actions = {
    request: fetchMetricsRequest,
    success: fetchMetricsSuccess,
    failure: fetchMetricsFailure,
  };
  return httpClient.run(Api.get, url, {}, actions);
};

//=======AREA CHART========
const fetchChartValuesRequest = () => ({
  type: actionTypes.FETCH_CHART_VALUES_REQUEST,
});

const fetchChartValuesSuccess = (payload) => ({
  type: actionTypes.FETCH_CHART_VALUES_SUCCESS,
  payload,
});

const fetchChartValuesFailure = (payload) => ({
  type: actionTypes.FETCH_CHART_VALUES_FAILURE,
  payload,
});

export const fetchChartValues = (filter,type) => {
  const url = `/api/bridge/metrics/dau/${filter}?type=${type}`;
  const actions = {
    request: fetchChartValuesRequest,
    success: fetchChartValuesSuccess,
    failure: fetchChartValuesFailure,
  };
  return httpClient.run(Api.get, url, {}, actions);
};