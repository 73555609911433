import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
        margin: theme.spacing(0.5),
        border: 0,
        "&.Mui-disabled": {
            border: 0
        },
        "&:not(:first-of-type)": {
            borderRadius: theme.shape.borderRadius
        },
        "&:first-of-type": {
            borderRadius: theme.shape.borderRadius
        }
    }
}));

export default function LanguageToggleButtons({ changeHandler, ismultiSelect = true }) {
    const [selectedLanguages, setSelectedLanguages] = React.useState(() => {
        if (ismultiSelect) {
            return ["sv", "en", "fr"];
        } else {
            return "sv"
        }
    }
    );

    const handleLanguages = (_event, newFilters) => {
        if (!ismultiSelect && !newFilters) {
            setSelectedLanguages((previous) => previous);
        } else {
            setSelectedLanguages(newFilters);
        }

        if (changeHandler && (ismultiSelect || (!ismultiSelect && newFilters))) {
            changeHandler(newFilters);
        }
    };

    return (
        <div>
            <Paper
                elevation={0}
                sx={{
                    display: "flex",
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    flexWrap: "wrap"
                }}
            >
                <StyledToggleButtonGroup
                    size="small"
                    exclusive={!ismultiSelect}
                    value={selectedLanguages}
                    onChange={handleLanguages}
                    aria-label="text formatting"
                >
                    <ToggleButton
                        value="sv"
                        color="success"
                    >
                        Swedish
                    </ToggleButton>
                    <ToggleButton
                        value="en"
                        color="success"
                    >
                        English
                    </ToggleButton>
                    <ToggleButton
                        value="fr"
                        color="success"
                    >
                        French
                    </ToggleButton>
                </StyledToggleButtonGroup>
            </Paper>
        </div>
    );
}
