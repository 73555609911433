export const FETCH_TAGS_REQUEST = "@TAGS/FETCH_TAGS_REQUEST";
export const FETCH_TAGS_SUCCESS = "@TAGS/FETCH_TAGS_SUCCESS";
export const FETCH_TAGS_FAILURE = "@TAGS/FETCH_TAGS_FAILURE";
export const ADD_TAG_REQUEST = "@TAGS/ADD_TAG_REQUEST";
export const ADD_TAG_SUCCESS = "@TAGS/ADD_TAG_SUCCESS";
export const ADD_TAG_FAILURE = "@TAGS/ADD_TAG_FAILURE";
export const UPDATE_TAG_REQUEST = "@TAGS/UPDATE_TAG_REQUEST";
export const UPDATE_TAG_SUCCESS = "@TAGS/UPDATE_TAG_SUCCESS";
export const UPDATE_TAG_FAILURE = "@TAGS/UPDATE_TAG_FAILURE";
export const DELETE_TAG_REQUEST = "@TAGS/DELETE_TAG_REQUEST";
export const DELETE_TAG_SUCCESS = "@TAGS/DELETE_TAG_SUCCESS";
export const DELETE_TAG_FAILURE = "@TAGS/DELETE_TAG_FAILURE";
export const SET_SELECTED_TAG = "@TAGS/SET_SELECTED_TAG";
export const SET_FLITER_TAG = "@TAGS/SET_FLITER_TAG";
export const SHOW_TAG_MODAL = "@TAGS/SHOW_TAG_MODAL";
export const SET_CLEAR_FORM = "@TAGS/SET_CLEAR_FORM";
