import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import If from 'ui/If/If';

const chartOptions = {
    chart: {
        zoom: {
            autoScaleYaxis: true
        },
        toolbar: {
            show: false
        },
    },
    dataLabels: {
        enabled: false
    },
    markers: {
        size: 0,
        style: 'hollow',
    },
    stroke: {
        curve: 'smooth',
        width: 0.7,
    },
    xaxis: {
        type: 'datetime',
        tickAmount: 1,
    },
    yaxis: {
        type: 'number',
        min: 0,
        forceNiceScale: true,
        decimalsInFloat: 0,
    },
    tooltip: {
        x: {
            format: 'dd MMM yyyy'
        }
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100]
        }
    }
};


const MetricsAreaChart = () => {
    const chartData = useSelector(state => state.dashboard.chartData)
    const [series, setSeries] = useState(null);

    useEffect(() => {
        if (!chartData) {
            return;
        }

        setSeries([{
            name: "serie",
            data:
                chartData.map(item => [item.date, item.value])
        }])
    }, [chartData]);

    return (
        <If when={series}>
            <ReactApexChart options={chartOptions} series={series} type="area" height={345} />
        </If>
    );
};

export default MetricsAreaChart;