import { cloneElement, forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSpring, animated } from '@react-spring/web';
import { Grid, TextField, Button, Modal, Box, Backdrop, FormControl, InputLabel, Select, Stack, MenuItem, OutlinedInput } from '@mui/material';
import { languages } from "util/languages";
import { fetchAllOrganizations } from 'store/organizations/actions';
import { addTag, fetchAllTags, setClearForm, setSelectedTag, showTagModal, updateTag } from 'store/tags/actions';

const Fade = forwardRef(function Fade(props, ref) {
    const {
        children,
        in: open,
        onClick,
        onEnter,
        onExited,
        ownerState,
        ...other
    } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter(null, true);
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited(null, true);
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {cloneElement(children, { onClick })}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element.isRequired,
    in: PropTypes.bool,
    onClick: PropTypes.any,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
    ownerState: PropTypes.any,
};

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function TagForm() {
    const dispatch = useDispatch();
    const organizations = useSelector((state) => state?.organizations?.filteredOrganizations);
    const selectedTag = useSelector((state) => state?.tags?.selectedTag);
    const canClearForm = useSelector((state) => state?.tags?.clearForm);
    const showModal = useSelector((state) => state?.tags?.showModal);

    const [formValues, setFormValues] = useState({
        name: "",
        language: "sv",
        organizationId: "",
        priority: 1,
    });
    const [formValidations, setFormValidations] = useState({
        nameIsValid: true,
        languageIsValid: true,
        priorityIsValid: true,
    });

    useEffect(() => {
        if (!showModal) {
            return;
        }

        if (!organizations || organizations.length === 0) {
            dispatch(fetchAllOrganizations());
            return;
        }

        if (!selectedTag) {
            setFormValues({
                name: "",
                language: "sv",
                priority: 1,
                organizationId: ""
            })
        } else {
            setFormValues({
                name: selectedTag.name,
                language: selectedTag.language,
                priority: selectedTag.priority,
                organizationId: selectedTag?.organizationId ?? ""
            })
        }

        setFormValidations({
            nameIsValid: true,
            languageIsValid: true,
            priorityIsValid: true,
        })
    }, [showModal, organizations, selectedTag])

    useEffect(() => {
        if (!canClearForm || !showModal) {
            return;
        }

        dispatch(showTagModal(false));
        dispatch(setClearForm());
        dispatch(fetchAllTags());

        if (selectedTag) {
            // Edit Mode
            dispatch(setSelectedTag(null));
            return;
        }
    }, [canClearForm])

    const checkFormValidation = () => {
        let isValid = true;

        if (!formValues.name) {
            setFormValidations(prev => ({ ...prev, nameIsValid: false }));
            isValid = false;
        }

        if (!formValues.language) {
            setFormValidations(prev => ({ ...prev, languageIsValid: false }));
            isValid = false;
        }
        if (!formValues.priority) {
            setFormValidations(prev => ({ ...prev, priorityIsValid: false }));
            isValid = false;
        }

        return isValid;
    }

    const handleValueChange = (key, value) => {
        setFormValues((prev) => ({ ...prev, [key]: value }))
        setFormValidations(prev => ({ ...prev, [`${key}IsValid`]: !!value }))
    }

    const handleClose = () => dispatch(showTagModal(false));

    const handleSave = () => {
        if (!checkFormValidation()) {
            return;
        }

        if (selectedTag) {
            dispatch(updateTag({
                ...selectedTag,
                ...formValues
            }))
        } else {
            dispatch(addTag(formValues))
        }
    };


    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                open={showModal}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        TransitionComponent: Fade,
                    },
                }}
            >
                <Fade in={showModal}>
                    <Box sx={style}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Tag"
                                    error={!formValidations.nameIsValid}
                                    helperText={formValidations.nameIsValid ? "" : "please fill this field."}
                                    defaultValue={formValues.name}
                                    value={formValues.name}
                                    onChange={(e) => handleValueChange("name", e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-lang">Language</InputLabel>
                                    <Select
                                        labelId="lbl-lang"
                                        value={formValues.language}
                                        onChange={(e) => handleValueChange("language", e.target.value)}
                                        input={<OutlinedInput label="language" />}
                                    >
                                        {languages.map((lang) => (
                                            <MenuItem
                                                key={lang.id}
                                                value={lang.id}
                                            >
                                                {lang.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth>
                                    <InputLabel id="lbl-org">Organization</InputLabel>
                                    <Select
                                        labelId="lbl-org"
                                        disabled={!!selectedTag}
                                        value={formValues.organizationId}
                                        onChange={(e) => handleValueChange("organizationId", e.target.value)}
                                        input={<OutlinedInput label="organization" />}
                                    >
                                        <MenuItem value="">
                                            <em>Not selected</em>
                                        </MenuItem>
                                        {organizations.map((org) => (
                                            <MenuItem
                                                key={org.id}
                                                value={org.id}
                                            >
                                                {org.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required
                                    fullWidth
                                    label="Priority"
                                    type="number"
                                    error={!formValidations.priorityIsValid}
                                    helperText={formValidations.priorityIsValid ? "" : "please fill this field."}
                                    InputProps={{
                                        inputProps: {
                                            min: 1
                                        }
                                    }}
                                    defaultValue={formValues.priority}
                                    value={formValues.priority}
                                    onChange={(e) => handleValueChange("priority", +e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} marginTop={3}>
                                <Stack direction="row" justifyContent="flex-end" spacing={2}>
                                    <Button variant="outlined" color="error" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="outlined" onClick={handleSave}>
                                        Save
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
        </div >
    );
}