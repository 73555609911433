import * as actionTypes from "./actions.types";

export const initialState = {
  tags: [],
  filteredTags: [],
  selectedTag: null,
  filter: null,
  isLoading: false,
  clearForm: false,
  showModal: false,
};

const fetchTagsRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const fetchTagsSuccess = (state, action) => {
  let filteredTags = action.payload.sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  if (state?.filter && state?.filter?.length > 0) {
    filteredTags = action.payload.filter((tag) =>
      state?.filter.includes(tag.language)
    );
  }

  return {
    ...state,
    isLoading: false,
    tags: action.payload,
    filteredTags,
  };
};

const fetchTagsFailure = (state) => {
  return {
    ...state,
    tags: [],
    filteredTags: [],
    isLoading: false,
  };
};

const setSelectedTag = (state, action) => {
  return {
    ...state,
    selectedTag: action.payload,
  };
};

const setFilter = (state, action) => {
  let filteredTags = state.tags;

  if (action.payload && action.payload.length > 0) {
    filteredTags = state.tags.filter((tag) =>
      action.payload.includes(tag.language)
    );
  }

  return {
    ...state,
    filter: action.payload,
    filteredTags,
  };
};

const addTagRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const addTagSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm: true,
  };
};

const addTagFailure = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm: false,
  };
};

const updateTagRequest = (state) => {
  return {
    ...state,
    isLoading: true,
    clearForm: false,
  };
};

const updateTagSuccess = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm: true,
  };
};

const updateTagFailure = (state) => {
  return {
    ...state,
    isLoading: false,
    clearForm: false,
  };
};

const deleteTagRequest = (state) => {
  return {
    ...state,
    isLoading: true,
  };
};

const deleteTagSuccess = (state, action) => {
  const updatedTagsList = state.tags.filter((tag) => tag.id !== action.payload);
  const updatedFilteredTagsList = state.filteredTags.filter(
    (tag) => tag.id !== action.payload
  );

  return {
    ...state,
    isLoading: false,
    tags: updatedTagsList,
    filteredTags: updatedFilteredTagsList,
    selectedTag: null,
  };
};

const deleteTagFailure = (state) => {
  return {
    ...state,
    isLoading: false,
  };
};

const showTagModal = (state, action) => {
  return {
    ...state,
    showModal: action.payload,
    clearForm: false,
  };
};

const setClearForm = (state, action) => {
  return {
    ...state,
    clearForm: action.payload,
  };
};

const reducerMap = {
  [actionTypes.FETCH_TAGS_REQUEST]: fetchTagsRequest,
  [actionTypes.FETCH_TAGS_SUCCESS]: fetchTagsSuccess,
  [actionTypes.FETCH_TAGS_FAILURE]: fetchTagsFailure,
  [actionTypes.ADD_TAG_REQUEST]: addTagRequest,
  [actionTypes.ADD_TAG_SUCCESS]: addTagSuccess,
  [actionTypes.ADD_TAG_FAILURE]: addTagFailure,
  [actionTypes.UPDATE_TAG_REQUEST]: updateTagRequest,
  [actionTypes.UPDATE_TAG_SUCCESS]: updateTagSuccess,
  [actionTypes.UPDATE_TAG_FAILURE]: updateTagFailure,
  [actionTypes.DELETE_TAG_REQUEST]: deleteTagRequest,
  [actionTypes.DELETE_TAG_SUCCESS]: deleteTagSuccess,
  [actionTypes.DELETE_TAG_FAILURE]: deleteTagFailure,
  [actionTypes.SET_SELECTED_TAG]: setSelectedTag,
  [actionTypes.SET_FLITER_TAG]: setFilter,
  [actionTypes.SHOW_TAG_MODAL]: showTagModal,
  [actionTypes.SET_CLEAR_FORM]: setClearForm,
};

const tagsReducer = (state = initialState, action) => {
  if (reducerMap[action.type]) {
    return reducerMap[action.type](state, action);
  }

  return state;
};

export default tagsReducer;
